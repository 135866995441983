import { DefaultApiInstance } from "@/api";
import { AuthApi } from "@/api/authApi";

export default {
  namespaced: true,
  state() {
    return {
      credentials: {
        token: localStorage.getItem("token") || null,
      },
      user: JSON.parse(localStorage.getItem("user")) || null,
      userRole: localStorage.getItem("userRole") || null,
      experience: 0,
    };
  },
  getters: {
    getUserRole(state) {
      return state.userRole;
    },
    getUserRegion(state) {
      return state.user.region_id || 56;
    },
    getUser(state) {
      return state.user;
    },
    getUserName(state) {
      return state.user?.name;
    },
  },
  mutations: {
    setToken(state, token) {
      state.credentials.token = token;
      localStorage.setItem("token", token);
    },

    setIsAuth(state, auth) {
      state.isAuth = auth;
    },

    setUser(state, user) {
      state.user = user;
      state.userRole = user.roles[0].slug;
      localStorage.setItem("userRole", user.roles[0].slug);
      localStorage.setItem("user", JSON.stringify(user));
    },

    deleteToken(state) {
      state.credentials.token = null;
      localStorage.removeItem("token");
    },

    deleteUser(state) {
      state.user = {};
      state.userRole = null;
      localStorage.removeItem("user");
      localStorage.removeItem("userRole");
    },
  },
  actions: {
    async onLogin({ commit }, { login, password, accept }) {
      try {
        return await AuthApi.login(login, password, accept).then(res => {
          commit("setToken", res.data.access_token);
          DefaultApiInstance.defaults.headers[
            "authorization"
          ] = `Bearer ${res.data.access_token}`;
        });
      } catch (error) {
        alert(error.response.data.message);
      }
    },

    async getAuthUserData({ commit }) {
      return await AuthApi.getAuthUserData().then(res => {
        commit("setUser", res.data);
      });
    },

    onLogout({ commit }) {
      commit("deleteToken");
      commit("setIsAuth", false);
      commit("deleteUser");
      localStorage.removeItem("raw-awards-count");
      DefaultApiInstance.defaults.headers["authorization"] = "";
    },
  },
};
