<template>
  <div class="my-input__wrapper">
    <label class="my-input__label" :for="inputName">{{ inputName }}</label>
    <input
      v-imask="phoneMask"
      class="my-input"
      :type="type"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :id="inputName"
      :value="value"
      @accept="onAccept"
    />
    <div class="my-input__error" v-if="isInvalid">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
export default {
  name: "MyPhoneInput",
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      phoneMask: {
        mask: "+{7}(000)000-00-00",
      },
    };
  },
  props: {
    type: String,
    placeholder: String,
    isInvalid: Boolean,
    inputName: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: String,
    errorText: String,
  },
  methods: {
    onAccept(e) {
      const maskRef = e.detail;
      // this.phoneValue = maskRef.unmaskedValue;
      this.$emit("input", maskRef.unmaskedValue);
    },
  },
  watch: {
    value(nv) {
      console.log(this.$root);
    },
  },
  mounted() {
    console.log(this.imask);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/UI/variables.scss";
@import "@/styles/mixins/flex";

.my-input {
  width: 100%;
  background: $mainGray;
  border: none;
  outline: none;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  border-radius: 0.25rem;

  &__wrapper {
    @include flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    input {
      border: 2px solid transparent;
    }
    input:focus {
      border: 2px solid #333;
    }
  }

  &__label {
    font-size: $fontS;
    white-space: nowrap;
  }

  &__error {
    font-size: $fontS;
    color: $errorTextColor;
  }
}
</style>
