import { FiltersApi } from "@/api/filtersApi";
import { OrdersApi } from "@/api/ordersApi";
import qs from "qs";
import { DefaultApiInstance } from "@/api";

export default {
  namespaced: true,
  state() {
    return {
      orders: [],
      userRole: localStorage.getItem("userRole") ?? null,
      isOrdersLoading: false,
      searchQuery: localStorage.getItem("searchQuery") || "",
      searchedOrders: [],
      isLoading: false,
      // listType для специалистов ТО
      listType: localStorage.getItem("listType") || "",
      // стейт для пагинации
      perPage: Number(localStorage.getItem("perPage")) || 15,
      total: null,
      from: null,
      to: null,
      first: null,
      next: null,
      prev: null,
      last: null,
      currentPage: Number(localStorage.getItem("currentPage")) || 1,
      isFilterFlag: false,

      // scope
      scope: localStorage.getItem("scope") || "",
      //!!   refactor
      filters: JSON.parse(localStorage.getItem("ordersFilters")) || [],
      filtersWithEqualKeys: [],
      // sort
      sortType: localStorage.getItem("sortType") || null,
      sortValue: localStorage.getItem("sortValue") || null,
    };
  },
  actions: {
    getOrders({ getters, commit }) {
      commit("setIsOrdersLoading", true);

      const role = getters.getRole;
      const scope = getters.getScope;
      const listType = getters.getListType.length
        ? `&list_type=${getters.getListType}`
        : "";
      let queryString = getters.getFiltersQueryString;
      let page = getters.getCurrentPage;
      let perPage = getters.getPerPage;
      let searchQuery = getters.getSearchQuery;
      let url = `/${role}/orders?with[]=client.requisites&with[]=client.addresses&with[]=manager.supervisors&with[]=lastOrderStatusData`;

      // исключить транзиты
      if (!queryString.includes("status")) {
        if (!searchQuery.length) {
          url += "&scope[]=except_status_mpp_transit";
        }
      }

      if (searchQuery.length) {
        url += `&search=${searchQuery}`;
        commit("setCurrentPage", 1);
        page = 1;
        commit("setPerPage", 15);
        perPage = 15;
      }

      // предусмотреть фильтры

      // предусмотреть скоуп
      if (scope.length) {
        url += `&${scope}`;
      }

      if (listType.length) {
        url += listType;
      }

      if (queryString.length) {
        url += `&${queryString}`;
      }

      if (getters.getIsSorted) {
        url += `&sort=${getters.getSortValue}|${getters.getSortType}`;
      }

      if (url.includes("red_header")) {
        url.replaceAll("&scope[]=except_status_mpp_transit", "");
      }

      url += `&page=${page}&per_page=${perPage}`;

      return OrdersApi.getOrders(url)
        .then(res => {
          commit("setOrders", res.data);
          commit("setPages", res.data.meta);
          commit("setLinks", res.data.links);
        })
        .finally(() => {
          commit("setIsOrdersLoading", false);
        })
        .catch(e => {
          console.log(e);
        });
    },
  },

  mutations: {
    setOrders(state, orders) {
      state.orders = orders;
    },

    setIsOrdersLoading(state, isLoading) {
      state.isOrdersLoading = isLoading;
    },

    setSearchQuery(state, searchQuery) {
      localStorage.setItem("searchQuery", searchQuery);
      state.searchQuery = searchQuery;
    },

    deleteOrders(state) {
      state.orders = [];
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },

    setUserRole(state, payload) {
      state.userRole = payload;
    },

    // pages

    setPages(state, meta) {
      state.currentPage = meta.current_page;
      state.total = meta.total;
      state.from = meta.from;
      state.to = meta.to;
    },

    setCurrentPage(state, page) {
      localStorage.setItem("currentPage", page);
      state.currentPage = page;
    },

    // links

    setLinks(state, links) {
      state.prev = links.prev;
      state.first = links.first;
      state.last = links.last;
      state.next = links.next;
    },

    // scope

    setScope(state, payload) {
      localStorage.setItem("scope", payload);
      state.scope = payload;
    },

    //   list type for TO

    setListType(state, payload) {
      localStorage.setItem("listType", payload);
      state.listType = payload;
    },

    //   refactor

    setPerPage(state, perPage) {
      localStorage.setItem("perPage", perPage);
      state.perPage = perPage;
    },

    setFilters(state, payload) {
      if (!state.filters.filter(f => f.key === payload.key).length) {
        state.filters.push(payload);
      } else {
        state.filters = state.filters.map(f => {
          return f.key === payload.key
            ? { ...f, type: payload.type, value: payload.value }
            : f;
        });
      }
    },

    setFiltersWithEqualKeys(state, payload) {
      if (
        !state.filtersWithEqualKeys.filter(
          f => f.filtersType === payload.filtersType
        ).length
      ) {
        state.filtersWithEqualKeys.push(payload);
      } else {
        state.filtersWithEqualKeys = state.filtersWithEqualKeys.map(f => {
          return f.filtersType === payload.filtersType
            ? { ...f, value: payload.value }
            : f;
        });
      }
    },

    setSortType(state, sortType) {
      state.sortType = sortType;
      localStorage.setItem("sortType", sortType);
    },

    setSortValue(state, sortValue) {
      state.sortValue = sortValue;
      localStorage.setItem("sortValue", sortValue);
    },

    clearFilter(state, { filtersType, key }) {
      state.currentPage = 1;

      key !== null
        ? (state.filters = state.filters.filter(f => f.key !== key))
        : (state.filtersWithEqualKeys = state.filtersWithEqualKeys.filter(
            f => f.filtersType !== filtersType
          ));
    },

    clearFilters(state) {
      state.currentPage = 1;
      state.sortType = null;
      state.sortValue = null;
      state.searchQuery = "";
      const items = { ...localStorage };

      localStorage.removeItem("ordersFilters");
      localStorage.removeItem("sortType");
      localStorage.removeItem("sortValue");

      state.filtersWithEqualKeys = [];

      Object.keys(items).forEach(key => {
        if (key.includes("-filter")) {
          localStorage.removeItem(key);
        }
      });

      state.filters = [];
    },
  },

  getters: {
    allOrders(state) {
      return state.orders.data;
    },

    getIsOrdersLoading(state) {
      return state.isOrdersLoading;
    },

    getScope(state) {
      return state.scope;
    },

    getListType(state) {
      return state.listType;
    },

    getRole(state) {
      return state.userRole;
    },

    // pages

    getCurrentPage(state) {
      return state.currentPage;
    },

    getTotalPages(state) {
      return state.orders?.meta?.last_page;
    },

    getTotalOrders(state) {
      return state.orders?.meta?.total;
    },

    //refactor
    getPerPage(state) {
      return state.perPage;
    },

    getSortType(state) {
      return state.sortType;
    },

    getSortValue(state) {
      return state.sortValue;
    },

    getIsSorted(state) {
      return !!(state.sortType && state.sortValue);
    },

    getFilters(state, getters) {
      const result = [];
      const filtersWithEqualKeys = [...getters.getFiltersWithEqualKeys].map(
        f => ({ ...f })
      );
      state.filters.forEach(f => {
        if (Array.isArray(f.value && f.value.length)) {
          result.push(f);
        } else {
          if (Object.keys(f.value).length) {
            result.push(f);
          }
        }
      });

      if (filtersWithEqualKeys.length) {
        filtersWithEqualKeys.forEach(f => {
          delete f.filtersType;
          result.push(f);
        });
      }

      result.length
        ? localStorage.setItem("ordersFilters", JSON.stringify(result))
        : localStorage.removeItem("ordersFilters");

      return result;
    },

    getFiltersWithEqualKeys(state) {
      return state.filtersWithEqualKeys;
    },

    getFiltersQueryString(state, getters) {
      return qs
        .stringify({ filters: getters.getFilters })
        .replaceAll("%5B", "[")
        .replaceAll("%5D", "]");
    },

    getSearchQuery(state) {
      return state.searchQuery;
    },
  },
};
