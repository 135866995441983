<template>
  <div class="order">
    <div class="main-page__header header">
      <div class="container">
        <div class="header__inner">
          <div class="header__info">
            <div class="header__logo">
              <HeaderIcon name="logo" />
            </div>
            <HeaderLinks />
          </div>
          <div class="header__actions"></div>
          <div class="input__wrapper">
            <input
              v-model="searchQuery"
              class="header__actions-input"
              type="text"
              placeholder="Найти пользователя"
              @input="debounceFetchSearchedUsers()"
            />
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import HeaderLinks from "@/components/header/HeaderLinks";
import HeaderIcon from "@/components/UI/HeaderIcon";
import _debounce from "lodash/debounce";
export default {
  name: "userList-layout",
  components: {
    HeaderLinks,
    HeaderIcon,
  },
  data() {
    return {
      toRoles: ["to", "supervisor_to"],
      digestRoles: ["admin", "sale_director"],
      windowWidth: window.innerWidth,
      searchQuery: "",
    };
  },
  computed: {
    userRole() {
      return this.$store.state.authModule.userRole;
    },
    getQueryParam() {
      return this.$route.name;
    },
    getUserName() {
      return this.$store.state.authModule.user.name;
    },
    debounceFetchSearchedUsers() {
      return _debounce(this.fetchSearchedUsers, 300);
    },
  },
  methods: {
    navigateToDigest(e) {
      this.$router.push(e.target.dataset.digest);
    },
    fetchSearchedUsers() {
      this.$store.dispatch("usersModule/fetchSearchedUsers", {
        role: this.userRole,
        searchQuery: this.searchQuery,
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.header__actions-username {
  font-weight: 600;
  font-size: 24px;
}
.header__actions-input {
  width: 300px !important;
}
</style>
