import { DefaultApiInstance } from "@/api";
import qs from "qs";
import _groupBy from "lodash/groupBy";
import { AwardsApi } from "@/api/awardsApi";

export default {
  namespaced: true,
  state() {
    return {
      awardsList: [],
      isLoading: false,
      awardsListForRawAward: [],
    };
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },

    getAwardsList(state) {
      return state.awardsList;
    },

    getAwardsListForRawAward(state) {
      return state.awardsListForRawAward;
    },

    getAwardsListWithoutHr(state, getters) {
      const awards = getters.getAwardsList;
      return [...awards]
        .sort((a, b) => b.value - a.value)
        .filter(a => a.description !== "Премия новичка");
    },

    getAwardsListWithStatus(state, getters) {
      const awardsList = getters.getAwardsListWithoutHr;
      return awardsList.map(a => {
        const isRejected = a.rejected_at;
        const isAccepted = a.accepted_at;
        const status =
          !isRejected && !isAccepted
            ? "На рассмотрении"
            : isRejected && !isAccepted
            ? "Отклонено"
            : "Согласовано";
        return { ...a, status };
      });
    },

    getRawAwardsCount(state, getters) {
      const awards = getters.getAwardsListWithStatus;
      const rawAwards = awards.filter(
        a => a.status === "На рассмотрении"
      ).length;
      return rawAwards;
    },

    getAwardListGroupByManager(state, getters) {
      const awardsList = getters.getAwardsList;

      return _groupBy(awardsList, "user_id");
    },
  },
  actions: {
    fetchAwardsList(
      { state, commit },
      { onlyAccepted = false, from, to, isHr = false }
    ) {
      let filters = [];

      state.isLoading = true;
      if (onlyAccepted) {
        // filters.push({
        //   type: "not_null",
        //   key: "accepted_at",
        // });

        filters.push({
          type: "between",
          key: "accepted_at",
          value: {
            gte: [from + " 00:00:00"],
            lte: [to + " 23:59:59"],
          },
        });
      }

      if (isHr) {
        filters.push({
          type: "eq",
          key: "creator.roles.slug",
          value: ["hr"],
        });
      }

      return AwardsApi.fetchAwards(filters)
        .then(res => {
          commit("updateAwardsList", res.data.data);
          if (!onlyAccepted) {
            commit("updateAwardsListForRawAward", res.data.data);
          }
          return res.data.data;
        })
        .finally(() => {
          state.isLoading = false;
        });
    },
  },
  mutations: {
    updateAwardsList(state, payload) {
      state.awardsList = payload;
    },

    updateAwardsListForRawAward(state, count) {
      state.awardsListForRawAward = count;
    },
  },
};
