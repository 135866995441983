<template>
  <div class="my-input__wrapper">
    <label v-if="isLabelShow" class="my-input__label" :for="inputName">{{
      inputName
    }}</label>

    <input
      @input="updateValue($event)"
      class="my-input"
      :class="{ invalid: isValidate }"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :disabled="isDisabled"
      :id="inputName"
    />

    <div class="my-input__error" v-show="isValidate">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MyInput",

  data() {
    return {};
  },
  props: {
    value: [String, Number],
    type: String,
    placeholder: String,
    isValidate: Boolean,
    inputName: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    errorText: String,
    isLabelShow: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    updateValue(event) {
      let value = event.target.value;

      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/UI/variables.scss";
@import "@/styles/mixins/flex";

.my-input {
  width: 100%;
  background: $mainGray;
  border: none;
  outline: none;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  border-radius: 0.25rem;

  &__wrapper {
    @include flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;

    input {
      border: 2px solid transparent;
    }
    input:focus {
      border: 2px solid #333;
    }
  }

  &__label {
    font-size: $fontS;
    white-space: nowrap;
  }

  &__error {
    font-size: $fontS;
    color: $errorTextColor;
  }
}
</style>
