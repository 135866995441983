<template>
  <svg
    v-if="name === 'logo'"
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.84548 17.8096C2.31427 17.0891 2.31427 14.9109 3.84548 14.1904L16.1484 8.40075C16.6878 8.14693 17.3122 8.14693 17.8516 8.40075L30.1545 14.1904C31.6857 14.9109 31.6857 17.0891 30.1545 17.8096L17.8516 23.5992C17.3122 23.8531 16.6878 23.8531 16.1484 23.5992L3.84548 17.8096Z"
      fill="#333333"
    />
    <path
      opacity="0.8"
      d="M3.84548 13.8096C2.31427 13.0891 2.31427 10.9109 3.84548 10.1904L16.1484 4.40075C16.6878 4.14693 17.3122 4.14693 17.8516 4.40075L30.1545 10.1904C31.6857 10.9109 31.6857 13.0891 30.1545 13.8096L17.8516 19.5992C17.3122 19.8531 16.6878 19.8531 16.1484 19.5992L3.84548 13.8096Z"
      fill="#333333"
    />
    <path
      opacity="0.3"
      d="M3.84548 9.80964C2.31427 9.08907 2.31427 6.91093 3.84548 6.19036L16.1484 0.400751C16.6878 0.146934 17.3122 0.146933 17.8516 0.40075L30.1545 6.19036C31.6857 6.91093 31.6857 9.08907 30.1545 9.80964L17.8516 15.5992C17.3122 15.8531 16.6878 15.8531 16.1484 15.5992L3.84548 9.80964Z"
      fill="#333333"
    />
  </svg>
  <svg
    v-else-if="name === 'orders'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.39986 4.32947V2.52947C4.39986 1.86673 4.93712 1.32947 5.59986 1.32947H16.9999C17.6626 1.32947 18.1999 1.86673 18.1999 2.52947V7.32947M1.4008 18.2705L1.40089 9.14615C1.4009 8.23223 1.40056 6.9305 1.40027 5.98795C1.40006 5.32503 1.9374 4.78826 2.60032 4.78826H8.31853L11.0836 7.74189H19.3998C20.0626 7.74189 20.5999 8.27917 20.5998 8.94193L20.5995 18.2706C20.5995 19.5961 19.525 20.6705 18.1995 20.6705L3.8008 20.6705C2.47531 20.6705 1.40079 19.596 1.4008 18.2705Z"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'user'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.67885 5.60002H6.80002M13.6182 8.38184L20.3071 15.0708C20.4947 15.2583 20.6 15.5127 20.6 15.7779V19.6C20.6 20.1523 20.1523 20.6 19.6 20.6H17.1091V17.9818H14.4909V15.3637L11.2661 12.3267C10.2299 13.136 8.92582 13.6182 7.50911 13.6182C4.13516 13.6182 1.40002 10.8831 1.40002 7.50911C1.40002 4.13516 4.13516 1.40002 7.50911 1.40002C10.8831 1.40002 13.6182 4.13516 13.6182 7.50911V8.38184Z"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'download'"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.19987 14.3999H2.99987C2.11621 14.3999 1.39987 13.6836 1.39987 12.7999L1.39993 3.19997C1.39994 2.31631 2.11628 1.59998 2.99993 1.59998H10.2001C11.0838 1.59998 11.8001 2.31632 11.8001 3.19998V7.59997M12.6001 12.7716L10.9639 14.4M10.9639 14.4L9.40012 12.8452M10.9639 14.4V10.4"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'upload'"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1999 14.3999H2.9999C2.11624 14.3999 1.3999 13.6836 1.3999 12.7999L1.39996 3.19997C1.39997 2.31631 2.11631 1.59998 2.99996 1.59998H10.2001C11.0838 1.59998 11.8001 2.31632 11.8001 3.19998V7.59997M9.40015 12.0284L11.0363 10.4M11.0363 10.4L12.6002 11.9547M11.0363 10.4V14.4"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'usersList'"
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M13 9.5C13 11.433 11.433 13 9.5 13C7.567 13 6 11.433 6 9.5C6 7.567 7.567 6 9.5 6C11.433 6 13 7.567 13 9.5Z"
      fill="#323232"
    />
    <path
      d="M15.6309 7.15517C15.9015 7.05482 16.1943 7 16.4999 7C17.8806 7 18.9999 8.11929 18.9999 9.5C18.9999 10.8807 17.8806 12 16.4999 12C16.1943 12 15.9015 11.9452 15.6309 11.8448"
      stroke="#323232"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M3 19C3.69137 16.6928 5.46998 16 9.5 16C13.53 16 15.3086 16.6928 16 19"
      stroke="#323232"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M17 15C19.403 15.095 20.5292 15.6383 21 17"
      stroke="#323232"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13 9.5C13 11.433 11.433 13 9.5 13C7.567 13 6 11.433 6 9.5C6 7.567 7.567 6 9.5 6C11.433 6 13 7.567 13 9.5Z"
      stroke="#323232"
      stroke-width="2"
    />
  </svg>

  <svg
    v-else-if="name === 'statistic'"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.40039 9.59927H6.20039L8.64892 1.59998L13.9262 18.4L15.7888 9.59927H20.6004"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'top-digest'"
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-5h2v5zm4 0h-2v-3h2v3zm0-5h-2v-2h2v2zm4 5h-2V7h2v10z"
    />
  </svg>

  <svg
    v-else-if="name === 'star'"
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2691 4.41115C11.5006 3.89177 11.6164 3.63208 11.7776 3.55211C11.9176 3.48263 12.082 3.48263 12.222 3.55211C12.3832 3.63208 12.499 3.89177 12.7305 4.41115L14.5745 8.54808C14.643 8.70162 14.6772 8.77839 14.7302 8.83718C14.777 8.8892 14.8343 8.93081 14.8982 8.95929C14.9705 8.99149 15.0541 9.00031 15.2213 9.01795L19.7256 9.49336C20.2911 9.55304 20.5738 9.58288 20.6997 9.71147C20.809 9.82316 20.8598 9.97956 20.837 10.1342C20.8108 10.3122 20.5996 10.5025 20.1772 10.8832L16.8125 13.9154C16.6877 14.0279 16.6252 14.0842 16.5857 14.1527C16.5507 14.2134 16.5288 14.2807 16.5215 14.3503C16.5132 14.429 16.5306 14.5112 16.5655 14.6757L17.5053 19.1064C17.6233 19.6627 17.6823 19.9408 17.5989 20.1002C17.5264 20.2388 17.3934 20.3354 17.2393 20.3615C17.0619 20.3915 16.8156 20.2495 16.323 19.9654L12.3995 17.7024C12.2539 17.6184 12.1811 17.5765 12.1037 17.56C12.0352 17.5455 11.9644 17.5455 11.8959 17.56C11.8185 17.5765 11.7457 17.6184 11.6001 17.7024L7.67662 19.9654C7.18404 20.2495 6.93775 20.3915 6.76034 20.3615C6.60623 20.3354 6.47319 20.2388 6.40075 20.1002C6.31736 19.9408 6.37635 19.6627 6.49434 19.1064L7.4341 14.6757C7.46898 14.5112 7.48642 14.429 7.47814 14.3503C7.47081 14.2807 7.44894 14.2134 7.41394 14.1527C7.37439 14.0842 7.31195 14.0279 7.18708 13.9154L3.82246 10.8832C3.40005 10.5025 3.18884 10.3122 3.16258 10.1342C3.13978 9.97956 3.19059 9.82316 3.29993 9.71147C3.42581 9.58288 3.70856 9.55304 4.27406 9.49336L8.77835 9.01795C8.94553 9.00031 9.02911 8.99149 9.10139 8.95929C9.16534 8.93081 9.2226 8.8892 9.26946 8.83718C9.32241 8.77839 9.35663 8.70162 9.42508 8.54808L11.2691 4.41115Z"
      stroke="#fff"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'game'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.89139 2.17203C10.2723 1.14253 11.7284 1.14253 12.1094 2.17203L14.0055 7.29616C14.1253 7.61984 14.3805 7.87503 14.7041 7.9948L19.8283 9.8909C20.8578 10.2718 20.8578 11.728 19.8283 12.1089L14.7041 14.005C14.3805 14.1248 14.1253 14.38 14.0055 14.7036L12.1094 19.8278C11.7284 20.8573 10.2723 20.8573 9.89139 19.8278L7.99529 14.7036C7.87552 14.38 7.62032 14.1248 7.29665 14.005L2.17251 12.1089C1.14302 11.728 1.14302 10.2719 2.17251 9.8909L7.29665 7.9948C7.62032 7.87503 7.87552 7.61984 7.99529 7.29617L9.89139 2.17203Z"
      stroke="#333333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="name === 'question'"
    width="22px"
    height="22px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <circle cx="12" cy="16" r="1" fill="#1C274C" />
    <path
      d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
      stroke="#1C274C"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
  <svg
    v-else-if="name === 'payments'"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="0 0 27.834 27.834"
    xml:space="preserve"
  >
    <g>
      <path
        d="M9.258,23.087v-2.576H6.991v-2.284h2.267v-1.279H6.991V14.66h2.267V6.69H16.8c1.894,0,3.164,0.374,3.892,1.143
		c0.72,0.763,1.07,2.098,1.07,4.08c0,1.913-0.367,3.198-1.123,3.932c-0.75,0.729-2.07,1.085-4.031,1.085l-0.681,0.012h-2.046
		l-0.195,0.004h-1.868v1.279h2.263v2.286h-2.263v2.576H9.258L9.258,23.087z M15.588,14.652c2.057,0,2.83-0.25,3.119-0.459
		c0.261-0.19,0.572-0.717,0.572-2.146c0-1.843-0.254-2.425-0.405-2.607c-0.142-0.169-0.595-0.454-2.052-0.454l-0.682-0.012h-4.324
		v5.677H15.588L15.588,14.652z M13.917,27.834C6.244,27.834,0,21.591,0,13.917S6.243,0,13.917,0s13.917,6.243,13.917,13.917
		S21.59,27.834,13.917,27.834z M13.917,3.001C7.897,3.001,3,7.898,3,13.918c0,6.021,4.897,10.917,10.917,10.917
		c6.021,0,10.917-4.896,10.917-10.917C24.834,7.898,19.938,3.001,13.917,3.001z"
      />
    </g>
  </svg>
  <svg
    v-else-if="name === 'managers-list'"
    width="22px"
    height="22px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6ZM2.25 10C2.25 9.58579 2.58579 9.25 3 9.25H21C21.4142 9.25 21.75 9.58579 21.75 10C21.75 10.4142 21.4142 10.75 21 10.75H3C2.58579 10.75 2.25 10.4142 2.25 10ZM2.25 14C2.25 13.5858 2.58579 13.25 3 13.25H11C11.4142 13.25 11.75 13.5858 11.75 14C11.75 14.4142 11.4142 14.75 11 14.75H3C2.58579 14.75 2.25 14.4142 2.25 14ZM17.5 13.25C17.9142 13.25 18.25 13.5858 18.25 14V18.1893L19.4697 16.9697C19.7626 16.6768 20.2374 16.6768 20.5303 16.9697C20.8232 17.2626 20.8232 17.7374 20.5303 18.0303L18.0303 20.5303C17.7374 20.8232 17.2626 20.8232 16.9697 20.5303L14.4697 18.0303C14.1768 17.7374 14.1768 17.2626 14.4697 16.9697C14.7626 16.6768 15.2374 16.6768 15.5303 16.9697L16.75 18.1893V14C16.75 13.5858 17.0858 13.25 17.5 13.25ZM2.25 18C2.25 17.5858 2.58579 17.25 3 17.25H11C11.4142 17.25 11.75 17.5858 11.75 18C11.75 18.4142 11.4142 18.75 11 18.75H3C2.58579 18.75 2.25 18.4142 2.25 18Z"
        fill="#000"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["name"],
};
</script>
