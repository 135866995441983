import { DefaultApiInstance } from "@/api";

export const FiltersApi = {
  getSearchedClients(role, searchQuery) {
    const url = `${role}/clients?search=${searchQuery}`;
    return DefaultApiInstance.get(url);
  },
  getSearchedRegions(role, searchQuery) {
    const url = `regions?search=${searchQuery}`;
    return DefaultApiInstance.get(url);
  },
  getSearchedRgp(role, searchQuery) {
    const url = `${role}/users/by-role?role=rgp&search=${searchQuery}`;
    return DefaultApiInstance.get(url);
  },
  getSearchedManagers(role, searchQuery) {
    const url = `${role}/users/by-role?role=manager&search=${searchQuery}`;
    return DefaultApiInstance.get(url);
  },
  getRgpSearchedManagers(role, searchQuery) {
    const url = `${role}/managers?search=${searchQuery}`;
    return DefaultApiInstance.get(url);
  },

  getFilteredOrders(
    role,
    params,
    perPage,
    sortType,
    sortValue,
    searchQuery,
    listType
  ) {
    const toParams = listType.length ? `&list_type=${listType}` : "";
    let searchParams = searchQuery ? `&search=${searchQuery}` : "";
    let sortParams = "";
    if (sortType && sortValue) {
      sortParams = `&sort=${sortValue}|${sortType}`;
    }
    const url = `${role}/orders?with[]=client.requisites&with[]=client.addresses&with[]=manager.supervisors&with[]=lastOrderStatusData${searchParams}${sortParams}&${params}&page=1&per_page=${perPage}${toParams}`;
    return DefaultApiInstance.get(url);
  },
};
