import { StatisticApi } from "@/api/statisticApi";
import { DateHelpers } from "@/utils/helpers/DateHelpers";
import { managerStatisticServices } from "@/services/manager-statistic";

export default {
  namespaced: true,
  state() {
    return {
      redFinanceRisks: null,
      redContracts: null,
      soldTable: null,
      soldChart: null,
      managers: null,
      soldHeaders: null,
      redHeaderData: null,
      managersLoading: false,
      // calendar
      managersPeriod: {
        dateTo: null,
        dateFrom: null,
      },
      soldPeriod: {
        dateTo: null,
        dateFrom: null,
      },
      defaultPeriod: {
        dateTo: null,
        dateFrom: null,
      },
      soldStatisticHeaders: {
        cnt: 0,
        sum: 0,
      },
      lostAndWithheld: null,
    };
  },
  getters: {
    getUserRole(state, getters, rootState) {
      return rootState.authModule.userRole;
    },

    getRedFinanceRisks(state) {
      return state.redFinanceRisks;
    },

    getRedContracts(state) {
      return state.redContracts;
    },

    getSoldHeaders(state) {
      return state.soldHeaders;
    },

    getSoldChart(state) {
      return state.soldChart;
    },

    getSoldTable(state) {
      return state.soldTable;
    },

    getLostAndWithheld(state) {
      return state.lostAndWithheld;
    },

    getManagers(state) {
      return state.managers;
    },

    getManagerStatisticWithConcatValues(state, getters) {
      const dataWithConcat = [];
      getters.getManagers?.forEach(manager => {
        let newManagerData = {};
        for (const key in manager) {
          if (
            typeof manager[key] === "string" &&
            key.includes("concat") &&
            manager[key] !== null
          ) {
            newManagerData[key] = manager[key].split(",").reduce((acc, el) => {
              acc += Number(el) / manager[key].split(",").length;
              return acc;
            }, 0);
          } else if (key.includes("name") && manager[key] === null) {
            newManagerData[key] = `Н/Д`;
          } else if (key.includes("concat") && manager[key] === null) {
            newManagerData[key] = 0;
          } else {
            newManagerData[key] = manager[key];
          }
        }
        dataWithConcat.push(newManagerData);
        newManagerData = {};
      });
      return dataWithConcat;
    },

    getIsManagersLoading(state) {
      return state.managersLoading;
    },

    getRedHeaderData(state) {
      return state.redHeaderData;
    },

    getDefaultPeriod(state) {
      return state.defaultPeriod;
    },

    getSoldPeriod(state) {
      return state.soldPeriod;
    },

    getManagersPeriod(state) {
      return state.managersPeriod;
    },
  },
  actions: {
    fetchRedHeaderStatistic({ getters, commit }) {
      const role = getters.getUserRole;
      return StatisticApi.fetchRedHeaderData(role)
        .then(res => {
          commit("setRedHeaderStatistic", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchRedFinanceRisks({ commit, getters }) {
      const role = getters.getUserRole;
      return StatisticApi.fetchRedFinanceRisks(role)
        .then(res => {
          commit("setRedFinanceRisks", ...res.data);
        })
        .catch(error => {
          console.log(e);
        });
    },

    fetchRedContracts({ commit, getters }) {
      const role = getters.getUserRole;
      return StatisticApi.fetchRedContracts(role)
        .then(res => {
          commit("setRedContracts", ...res.data);
        })
        .catch(error => {
          console.log(e);
        });
    },

    // sold headers
    fetchSoldHeaders({ getters, commit }) {
      const role = getters.getUserRole;
      const period = getters.getDefaultPeriod;
      return StatisticApi.fetchSoldHeaders(role, period)
        .then(res => {
          if (res.data.length) {
            commit("setSoldHeaders", res.data[0]);
          } else {
            commit("setSoldHeaders", null);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    fetchSoldTable({ commit, getters }) {
      const period = getters.getSoldPeriod;
      const role = getters.getUserRole;
      return StatisticApi.fetchSoldTable(role, period)
        .then(res => {
          commit("setSoldTable", ...res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchSoldChart({ commit, getters }) {
      const period = getters.getSoldPeriod;
      const role = getters.getUserRole;
      return StatisticApi.fetchSoldChart(role, period)
        .then(res => {
          commit("setSoldChart", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchLostAndWithheld({ commit, getters }) {
      const period = getters.getSoldPeriod;
      const role = getters.getUserRole;
      return StatisticApi.fetchLostAndWithheld(role, period)
        .then(res => {
          commit("setLostAndWithheld", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchManagers({ commit, getters }) {
      const period = getters.getManagersPeriod;
      const role = getters.getUserRole;
      commit("setIsManagersLoading", true);
      StatisticApi.fetchManagersData(role, period)
        .then(res => {
          commit("setManagers", res.data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          commit("setIsManagersLoading", false);
        });
    },
  },
  mutations: {
    setRedHeaderStatistic(state, data) {
      if (data.length) {
        state.redHeaderData = data[0];
      } else {
        state.redHeaderData = null;
      }
    },
    setRedFinanceRisks(state, data) {
      state.redFinanceRisks = data;
    },
    setRedContracts(state, data) {
      state.redContracts = data;
    },
    setSoldTable(state, data) {
      state.soldTable = data;
    },
    setSoldChart(state, data) {
      state.soldChart = data;
    },
    setManagers(state, data) {
      state.managers = data;
    },
    setSoldHeaders(state, data) {
      state.soldHeaders = data;
    },
    setLostAndWithheld(state, data) {
      state.lostAndWithheld = data;
    },
    // managers calendar
    setManagersPeriod(state, period) {
      state.managersPeriod = period;
    },

    // soldCalendar

    setDefaultPeriod(state) {
      const period = DateHelpers.getMonthPeriod();

      state.defaultPeriod.dateFrom = period.startDate;
      state.defaultPeriod.dateTo = period.endDate;
    },

    setSoldPeriod(state, { dateFrom, dateTo }) {
      state.soldPeriod.dateFrom = dateFrom;
      state.soldPeriod.dateTo = dateTo;
    },

    //   loading
    setIsManagersLoading(state, isLoading) {
      state.managersLoading = isLoading;
    },

    clearSoldPeriods(state) {
      state.defaultPeriod.dateFrom = null;
      state.defaultPeriod.dateTo = null;
      state.soldPeriod.dateFrom = null;
      state.soldPeriod.dateTo = null;
    },
  },
};
