import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Paginate from "vuejs-paginate";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import VueExcelXlsx from "vue-excel-xlsx";
import loader from "vue-ui-preloader";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import vSelect from "vue-select";

import uiComponents from "../src/components/UI/index";

Vue.config.productionTip = false;
Vue.component("PaginateVue", Paginate);
Vue.component("v-calendar", Calendar);
Vue.component("v-date-picker", DatePicker);
Vue.use(CKEditor);

Vue.use(VueExcelXlsx);
Vue.use(loader);
Vue.component("v-select", vSelect);

uiComponents.forEach(uiComponent => {
  Vue.component(uiComponent.name, uiComponent);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
