<template>
  <div
    v-if="
      userRole !== 'order_viewer' &&
      userRole !== 'fin_monitor' &&
      userRole !== 'marketer' &&
      userRole !== 'price_editor'
    "
    class="add-form__upload-file"
  >
    <input
      @change="$event => inputHandler($event)"
      class="add-form__upload-input"
      type="file"
      accept=".zip"
      multiple
    />
    <div class="add-form__upload-block">
      <button class="header__actions-btn unload">
        <HeaderIcon name="download" />
        Загрузить файлы
      </button>
    </div>
  </div>
</template>

<script>
import HeaderIcon from "@/components/UI/HeaderIcon.vue";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import { apiUrl } from "../../../config.json";
export default {
  components: {
    HeaderIcon,
  },
  data() {
    return {
      inputFile: "",
    };
  },
  computed: {
    userRole() {
      return this.$store.state.authModule.userRole;
    },
    ...mapState({
      // scope
      scope: state => state.ordersModule.scope,
    }),
  },
  methods: {
    async inputHandler(event) {
      this.$store.commit("ordersModule/setIsOrdersLoading", true);
      this.inputFile = event.target.files[0];
      const formData = new FormData();
      formData.append("file", this.inputFile);
      const token = localStorage.getItem("token");
      const res = await axios
        .post(`${apiUrl}/${this.userRole}/orders`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.data?.errors.length === 0) {
            const orders = res.data.orders;

            const ordersId = orders.map(el => el.order_id).join(",");
            alert(`Заказ ${ordersId} загружен`);
          } else {
            const errors = res.data?.errors;
            errors.forEach(err => {
              alert(
                `При загрузке файла ${err.file_name}, произошла ошибка: "${err.message}""`
              );
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.setIsLoading(false);

          err.hasOwnProperty("response")
            ? alert(err.response.data?.message)
            : alert(err.message);
        })
        .finally(() => {
          this.inputFile = "";
          this.$store.dispatch("ordersModule/getOrders");
          this.$store.commit("ordersModule/setIsOrdersLoading", false);
        });
    },
  },
};
</script>
