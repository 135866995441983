<template>
  <div class="statistic-header">
    <MainLoader :is-visible="isPageLoading" />
    <div class="statistic-header__tabs">
      <div
        @click="changeTabHandler($event)"
        data-digest="/prepaid-surcharge"
        class="statistic-header__tab"
        :class="{ active: getActiveTab === '/prepaid-surcharge' }"
      >
        Фактические сборы
      </div>
      <div
        @click="changeTabHandler($event)"
        data-digest="/prepaid-surcharge/plans"
        class="statistic-header__tab"
        :class="{ active: getActiveTab === '/prepaid-surcharge/plans' }"
      >
        Прогнозы поступлений
      </div>
    </div>
  </div>
</template>

<script>
import MainLoader from "@/components/UI/loader/Loader.vue";

export default {
  name: "PrepaidSurchargeHeader",
  components: { MainLoader },
  data() {
    return {
      activeTab: localStorage.getItem("digest") || "/statistic",
      isPageLoading: false,
    };
  },
  computed: {
    getActiveTab() {
      return this.activeTab;
    },
  },
  methods: {
    changeTabHandler(e) {
      this.isPageLoading = true;
      this.activeTab = e.target.dataset.digest;
      this.$router
        .push(e.target.dataset.digest)
        .finally(() => (this.isPageLoading = false));
    },
  },

  mounted() {
    this.activeTab = this.$route.path;
  },
};
</script>

<style lang="scss" scoped>
.statistic-header {
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
  }

  &__tab {
    padding: 15px;
    cursor: pointer;
    &.active {
      border-bottom: 2px solid #333333;
    }
  }
}
</style>
