import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const authGuard = function (to, from, next) {
  const isAuthorized = localStorage.getItem("token");
  const isRole = localStorage.getItem("userRole");
  if (!isAuthorized && !isRole) {
    next({ name: "login" });
  } else if (isRole === "marketer") {
    if (to.name === "orders" || to.name === "user" || to.name === "discount") {
      next();
    } else {
      next({ name: "orders" });
    }
  } else next();
};

const prepaidSurchargeGuard = (to, from, next) => {
  const role = localStorage.getItem("userRole");
  const isAuthorized = localStorage.getItem("token");
  const roles = ["director", "admin", "sale_director"];

  if (isAuthorized && !roles.includes(role)) {
    next({ name: "orders" });
  } else if (!isAuthorized && !role) {
    next({ name: "login" });
  } else next();
};

const documentsGuard = (to, from, next) => {
  const role = localStorage.getItem("userRole");
  const isAuthorized = localStorage.getItem("token");
  const roles = ["admin"];

  if (isAuthorized && !roles.includes(role)) {
    next({ name: "orders" });
  } else if (!isAuthorized && !role) {
    next({ name: "login" });
  } else next();
};

const loginGuard = function (to, from, next) {
  const isAuthorized = localStorage.getItem("token");
  const isRole = localStorage.getItem("userRole");
  if (isAuthorized && isRole) next({ name: "orders" });
  else next();
};

const createUserGuard = function (to, from, next) {
  const isAuthorized = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");
  const availableRoles = ["admin"];
  isAuthorized && availableRoles.includes(userRole)
    ? next()
    : next({ name: "orders" });
};

const routes = [
  {
    path: "/",
    name: "login",
    meta: { layout: "login" },
    component: () => import("../views/LoginView.vue"),
    beforeEnter: loginGuard,
  },
  {
    path: "/ref-link/*",
    name: "ref-link",
    meta: { layout: "orders" },
    component: () => import("../views/RefLinkLoginView.vue"),
  },
  {
    path: "/success",
    name: "ref-link-success",
    meta: { layout: "orders" },
    component: () => import("../views/RefLinkSuccessView.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    meta: { layout: "ordersHeader" },
    component: () => import("@/views/OrdersView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/order/:id",
    name: "order",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/OrderView.vue"),
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: "/user",
    name: "user",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/UserView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/newbie-awards",
    name: "newbie-awards",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/NewbieAwardsView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/users-list",
    name: "userList",
    meta: { layout: "userList" },
    component: () => import("@/views/UsersListView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/users-list/create",
    name: "userListCreate",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/users/UserListCreateView.vue"),
    beforeEnter: createUserGuard,
  },
  {
    path: "/users/edit-user/:id",
    name: "userEditPage",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/EditUserPageView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/users/user/:id",
    name: "userPage",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/UserPageView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/statistic",
    name: "statisticPage",
    meta: { layout: "digest" },
    component: () => import("@/views/StatisticView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/statistic/top-digest",
    name: "topDigestPage",
    meta: { layout: "digest" },
    component: () => import("@/views/TopDigestPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/address-search",
    name: "addressSearch",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/AddressSearchView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin-statistic",
    name: "adminStatistic",
    meta: { layout: "admin-statistic" },
    component: () => import("@/views/ManagersListView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/game",
    name: "game",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/GameView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/game/user/:id",
    name: "game-user",
    meta: { layout: "orderHeader" },
    props: true,
    component: () => import("@/views/GameView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/game/rating",
    name: "game-global",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/GlobalRatingView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/game/grade-rating/:id",
    name: "game-grade",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/GradeRatingView.vue"),
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: "/game/map",
    name: "game-map",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/GameMapView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/payments",
    name: "payments",
    meta: { layout: "payments" },
    component: () => import("@/views/PaymentsView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/payments/rgp",
    name: "payments-rgp",
    meta: { layout: "payments" },
    component: () => import("@/views/RgpPaymentsView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/payments/sale-table",
    name: "sale-table",
    meta: { layout: "payments" },
    component: () => import("@/views/sale-table/SaleTableView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/payments/awards",
    name: "payments-awards",
    meta: { layout: "payments" },
    component: () => import("@/views/AwardsView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/payments/:id",
    name: "payments-id",
    meta: { layout: "orderHeader" },
    component: () =>
      import(
        "@/components/payments/manager/manager-payments/ManagerPayments.vue"
      ),
    beforeEnter: authGuard,
  },
  {
    path: "/admin-statistic/colors",
    name: "admin-statistic-colors",
    meta: { layout: "admin-statistic" },
    component: () =>
      import("@/components/colors/colors-component/ColorsComponent.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin-statistic/not-sold-filter",
    name: "not-sold",
    meta: { layout: "admin-statistic" },
    component: () => import("@/views/NotSoldFilterView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/price-list",
    name: "price-list",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/PriceListView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/discount",
    name: "discount",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/DiscountView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/posts",
    name: "posts",
    meta: { layout: "orderHeader" },
    component: () => import("@/views/PostsView.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/prepaid-surcharge",
    name: "prepaid-surcharge-chart",
    meta: { layout: "prepaid-surcharge" },
    component: () => import("@/views/PrepaidSurchargeChartView.vue"),
    beforeEnter: prepaidSurchargeGuard,
  },
  {
    path: "/prepaid-surcharge/plans",
    name: "prepaid-surcharge-fact",
    meta: { layout: "prepaid-surcharge" },
    component: () => import("@/views/PrepaidSurchargeChartView.vue"),
    beforeEnter: prepaidSurchargeGuard,
  },
  {
    path: "/documents",
    name: "documents",
    meta: { layout: "orderHeader" },
    component: () => import("../views/documents/DocumentView.vue"),
    beforeEnter: documentsGuard,
    children: [
      {
        path: "/documents",
        name: "documents-list",
        meta: { layout: "orderHeader" },
        component: () =>
          import("../components/documents/documents-list/DocumentsList.vue"),
      },
      {
        path: "/companies",
        name: "companies-list",
        meta: { layout: "orderHeader" },
        component: () =>
          import("../components/documents/companies-list/CompaniesList.vue"),
      },
    ],
  },

  {
    path: "/documents/:id",
    name: "document",
    meta: { layout: "orderHeader" },
    component: () => import("../views/documents/DocumentView.vue"),
    beforeEnter: documentsGuard,
    children: [
      {
        path: "/documents/:id/keys",
        name: "documents-keys-list",
        meta: { layout: "orderHeader", listType: "documents" },
        component: () =>
          import("../components/documents/keys-list/KeysList.vue"),
      },
      {
        path: "/documents/:id/generate_by_template",
        name: "document-generate",
        meta: { layout: "orderHeader", listType: "documents" },
        component: () =>
          import(
            "../components/documents/generate-document/GenerateDocument.vue"
          ),
      },
    ],
  },

  {
    path: "/companies/:id",
    name: "companies",
    meta: { layout: "orderHeader" },
    component: () => import("../views/documents/DocumentView.vue"),
    beforeEnter: documentsGuard,
    children: [
      {
        path: "/companies/:id/keys",
        name: "companies-keys-list",
        meta: { layout: "orderHeader", listType: "companies" },
        component: () =>
          import("../components/documents/keys-list/KeysList.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
