import { DefaultApiInstance } from "@/api";
import qs from "qs";

export const PaymentsApi = {
  fetchBonusProducts() {
    const url = "/bonus-products";

    return DefaultApiInstance.get(url);
  },

  fetchManagerOrders(params) {
    const { role, filters } = params;

    let url = `/${role}/orders?with[]=bonuses.bonusProduct&with[]=lastOrderStatusData&with[]=manager.supervisors&without_pagination=1&sort=manager_region|asc&scope[]=add_is_provide_overdubs&`;

    let queryParams = qs
      .stringify({ filters })
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]")
      .replaceAll("[lte][0]", "[lte]")
      .replaceAll("[gte][0]", "[gte]");

    url += `${queryParams}`;

    return DefaultApiInstance.get(url);
  },

  fetchTransits(role, date, managerId) {
    let url = `/${role}/orders/transits?date=${date}`;

    if (managerId) url += `&manager_id=${managerId}`;

    return DefaultApiInstance.get(url);
  },

  fetchNewbieTransits(role, date, managerId) {
    let url = `/${role}/orders/newbie/transits?date=${date}`;

    if (managerId) url += `&manager_id=${managerId}`;

    return DefaultApiInstance.get(url);
  },

  fetchNewbieSalary(role, date, managerId) {
    let url = `/${role}/orders/newbie/salary?date=${date}`;

    if (managerId) url += `&manager_id=${managerId}`;

    return DefaultApiInstance.get(url);
  },
};
