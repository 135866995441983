export default {
  namespaced: true,
  state() {
    return {
      isOpen: false,
      closing: false,
    };
  },
  getters: {
    getIsOpen(state) {
      return state.isOpen;
    },
    getClosing(state) {
      return state.closing;
    },
  },
  mutations: {
    toggleModal(state, boolean) {
      state.isOpen = boolean;
    },
  },
};
