<template>
  <div class="to-header" :class="{ secure: toSpecialist }">
    <!--    <div class="to-header__user">
      <p class="to-header__user-title">Специалист ТО:</p>
      <p class="to-header__user-name">
        {{ order?.to_name ? order?.to_name : "Не закреплен" }}
      </p>
    </div>-->
    <div v-if="!toSpecialist" class="to-header__secure">
      <div
        @click.stop="secureOrder"
        class="to-header__secure-button button-main"
      >
        Взять этот заказ
      </div>
    </div>
  </div>
</template>

<script>
import { DefaultApiInstance } from "@/api";

export default {
  name: "ToOrderHeader",
  data() {
    return {};
  },
  props: ["order"],
  computed: {
    user() {
      return this.$store.state.authModule.user;
    },
    userName() {
      return this.user?.name;
    },
    toSpecialist() {
      return this.order?.to_name;
    },
  },
  methods: {
    secureOrder() {
      const id = this.$props.order?.id;
      const url = `/to/orders/${id}/secure`;
      DefaultApiInstance.post(url)
        .then(res => {
          alert(`Заказ №${id} закреплен за пользователем ${this.userName}`);
          this.$router.go();
        })
        .catch(e => {
          const data = e.response.data.hasOwnProperty("message")
            ? e.response.data
            : null;
          console.log(e.response.data.message);
          if (data) {
            alert(data.message);
          } else {
            alert("Возникла ошибка");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.to-header {
  //width: 684px;
  z-index: 10000;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 8px;
  border-radius: 4px;

  &.secure {
    width: auto;
  }

  &__secure-button {
    height: 100%;
    color: white;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
  }
}
</style>
