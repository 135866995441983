export default {
  namespaced: true,
  state() {
    return {
      toRole: "",
      tabsTo: [
        {
          id: 0,
          name: "Новые заказы",
          listType: "new",
          active: true,
          role: ["to"],
        },
        {
          id: 1,
          name: "Мои заказы",
          listType: "self",
          active: false,
          role: ["to", "supervisor_to"],
        },
        {
          id: 2,
          name: "Требуют ответа",
          listType: "need_check",
          active: false,
          role: ["to", "supervisor_to"],
        },
        {
          id: 3,
          name: "В отделе ТО",
          listType: "all",
          active: false,
          role: ["to", "supervisor_to"],
        },
      ],
      tabsSupTo: [
        {
          id: 0,
          name: "Мои заказы",
          listType: "self",
          active: true,
          role: ["to", "supervisor_to"],
        },
        {
          id: 1,
          name: "Требуют ответа",
          listType: "need_check",
          active: false,
          role: ["to", "supervisor_to"],
        },
        {
          id: 2,
          name: "В отделе ТО",
          listType: "all",
          active: false,
          role: ["to", "supervisor_to"],
        },
      ],
    };
  },
  getters: {
    getTabs(state) {
      const tabs = [];
      if (state.toRole === "to") {
        state.tabsTo.forEach((tab, index) => {
          if (tab.role.includes(state.toRole)) {
            tabs.push(tab);
          }
        });
      } else {
        state.tabsSupTo.forEach((tab, index) => {
          if (tab.role.includes(state.toRole)) {
            tabs.push(tab);
          }
        });
      }
      return tabs;
    },
  },
  mutations: {
    setToRole(state, payload) {
      state.toRole = payload;
    },
  },
};
