import { TopDigestApi } from "@/api/topDigestApi";
import _sumBy from "lodash/sumBy";
import _sum from "lodash/sum";
import _groupBy from "lodash/groupBy";

export default {
  namespaced: true,
  state() {
    return {
      totalAnalytic: {
        analytic: null,
      },
      regionsAnalytic: {
        analytic: null,
      },
    };
  },
  getters: {
    getTotalAnalytic(state) {
      return state.totalAnalytic.analytic;
    },

    getTotalAnalyticByDates(_, getters) {
      return _groupBy(getters.getTotalAnalytic, "dates");
    },

    getAvgCheck(_, getters) {
      const result = {};

      Object.entries(getters.getTotalAnalyticByDates).forEach(
        ([date, values]) => {
          let concatTemp = [];
          // создал массив нужных значений
          values.forEach(val => {
            concatTemp.push(val.concat_amount_mpp?.split(","));
          });

          // добавил в один массив значения по всем ргп
          const concat = !concatTemp.length
            ? []
            : concatTemp.reduce((acc, el) => {
                return [...acc, ...el];
              }, []);

          result[date] = Math.floor(
            concat.reduce((acc, el) => {
              acc += Number(el);
              return acc;
            }, 0) /
              concat.length /
              1000
          );
        }
      );

      return result;
    },

    getSumTotalAnalyticByDates(_, getters) {
      const sums = {};
      Object.entries(getters.getTotalAnalyticByDates).forEach(
        ([date, values]) => {
          sums[date] = {
            amount: _sumBy(values, o => {
              return Number(o.amount);
            }),
            avg_check: "test",
            cnt: _sumBy(values, o => {
              return Number(o.cnt);
            }),
            prepaid: _sumBy(values, o => {
              return Number(o.prepaid);
            }),
          };
        }
      );
      return sums;
    },

    getTotalAnalyticBySupervisors(_, getters) {
      return _groupBy(getters.getTotalAnalytic, "rgp_name");
    },

    getSupervisorsByDates(_, getters) {
      const groupByDate = {};
      Object.entries(getters.getTotalAnalyticBySupervisors).forEach(
        ([sup, values]) => {
          groupByDate[sup] = _groupBy(values, "dates");
        }
      );
      return groupByDate;
    },

    getSumSupervisorsByDates(_, getters) {
      const sums = {};
      let sumsByRgp = {};
      Object.entries(getters.getSupervisorsByDates).forEach(([sup, values]) => {
        Object.entries(values).forEach(([rgp, values]) => {
          sumsByRgp[rgp] = {
            amount: _sumBy(values, o => {
              return Number(o.amount);
            }),
            avg_check: _sumBy(values, o => {
              return Number(o.avg_check);
            }),
            cnt: _sumBy(values, o => {
              return Number(o.cnt);
            }),
            prepaid: _sumBy(values, o => {
              return Number(o.prepaid);
            }),
          };
          sums[sup] = sumsByRgp;
        });
        sumsByRgp = {};
      });
      return sums;
    },
  },
  actions: {
    async fetchAnalytic({ commit }, { role }) {
      return TopDigestApi.fetchAnalytic(role).then(res => {
        commit("setTotalAnalytic", res.data);
      });
    },
  },
  mutations: {
    setTotalAnalytic(state, data) {
      state.totalAnalytic.analytic = data;
    },
  },
};
