import { PaymentsApi } from "@/api/paymentsApi";
import { AwardsApi } from "@/api/awardsApi";
import { AdminApi } from "@/api/adminApi";

export default {
  namespaced: true,
  state() {
    return {
      currentUserId: null,
      managerOrders: null,
      transits: null,
      newbieTransits: null,
      newbieSalary: null,
      awards: null,
      bonusProducts: null,
      user: null,
    };
  },
  getters: {
    getCurrentUserId(state) {
      return state.currentUserId;
    },

    getManagerOrders(state) {
      return state.managerOrders;
    },

    getTransits(state) {
      return state.transits;
    },

    getNewbieTransits(state) {
      return state.newbieTransits;
    },

    getNewbieSalary(state) {
      return state.newbieSalary;
    },

    getAwards(state) {
      return state.awards;
    },

    getBonusProducts(state) {
      return state.bonusProducts.data;
    },

    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setCurrentUserId(state, id) {
      state.currentUserId = id;
    },

    setManagersOrders(state, orders) {
      state.managerOrders = orders;
    },

    setTransits(state, transits) {
      state.transits = transits;
    },

    setNewbieTransits(state, transits) {
      state.newbieTransits = transits;
    },

    setNewbieSalary(state, salary) {
      state.newbieSalary = salary;
    },

    setAwards(state, awards) {
      state.awards = awards;
    },

    setBonusProducts(state, products) {
      state.bonusProducts = products;
    },

    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    fetchManagersOrders({ commit }, params) {
      return PaymentsApi.fetchManagerOrders(params)
        .then(res => {
          commit("setManagersOrders", res.data.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchTransits({ commit }, params) {
      const { role, date, id } = params;
      return PaymentsApi.fetchTransits(role, date, id)
        .then(res => {
          commit("setTransits", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchNewbieTransits({ commit }, params) {
      const { role, date, id } = params;
      return PaymentsApi.fetchNewbieTransits(role, date, id)
        .then(res => {
          commit("setNewbieTransits", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchNewbieSalary({ commit }, params) {
      const { role, date, id } = params;
      return PaymentsApi.fetchNewbieSalary(role, date, id)
        .then(res => {
          commit("setNewbieSalary", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchAwards({ commit }, filters) {
      return AwardsApi.fetchAwards(filters)
        .then(res => {
          commit("setAwards", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchBonusProducts({ commit }) {
      return PaymentsApi.fetchBonusProducts()
        .then(res => {
          commit("setBonusProducts", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchUser({ commit }, id) {
      return AdminApi.getUser(id)
        .then(res => {
          commit("setUser", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
};
