<template>
  <div class="header__info-buttons">
    <router-link
      v-if="!hrRoles.includes(userRole)"
      class="header__info-btn orders-btn"
      exact-active-class="active"
      to="/orders"
    >
      <HeaderIcon name="orders" />
      Заказы
    </router-link>

    <router-link
      v-if="hrRoles.includes(userRole)"
      class="header__info-btn user-btn"
      active-class="active"
      to="/newbie-awards"
    >
      <HeaderIcon name="usersList" />
      Пользователи
    </router-link>

    <router-link
      v-if="userRole === 'admin'"
      class="header__info-btn user-btn"
      active-class="active"
      to="/users-list"
    >
      <HeaderIcon name="usersList" />
      Пользователи
    </router-link>

    <!-- v-if="userRole === 'admin' || userRole === 'rgp'" -->
    <router-link
      v-if="
        !toRoles.includes(userRole) &&
        userRole !== 'bonus_product_specialist' &&
        userRole !== 'marketer' &&
        userRole !== 'price_editor' &&
        userRole !== 'hr'
      "
      class="header__info-btn statistic-btn"
      active-class="active"
      to="/statistic"
    >
      <HeaderIcon name="statistic" />
      Дайджест
    </router-link>

    <router-link
      v-if="addressSearchRoles.includes(userRole)"
      class="header__info-btn statistic-btn"
      active-class="active"
      to="/address-search"
    >
      <HeaderIcon name="top-digest" />
      База проектов
    </router-link>
    <router-link
      v-if="paymentsRoles.includes(userRole)"
      class="header__info-btn statistic-btn"
      active-class="active"
      :to="
        awardsCounterRole.includes(userRole) ? '/payments/awards' : '/payments'
      "
    >
      <HeaderIcon name="payments" />
      Зарплата
      <RawAwardsCount v-if="awardsCounterRole.includes(userRole)" />
    </router-link>
    <!--    /managers-list-->

    <router-link
      v-if="statisticRoles.includes(userRole)"
      class="header__info-btn statistic-btn"
      active-class="active"
      to="/admin-statistic"
    >
      <HeaderIcon name="managers-list" />
      Статистика
    </router-link>

    <!--    game-->

    <router-link
      v-if="userRole === 'admin'"
      class="header__info-btn user-btn"
      active-class="active"
      to="/game/rating"
    >
      <HeaderIcon name="game" />
      Рейтинг
    </router-link>

    <router-link
      v-else-if="userRole === 'manager'"
      class="header__info-btn user-btn"
      active-class="active"
      to="/game"
    >
      <HeaderIcon name="game" />
      Игра
    </router-link>

    <router-link
      class="header__info-btn user-btn"
      active-class="active"
      to="/user"
    >
      <HeaderIcon name="user" />
      Личный кабинет
    </router-link>
  </div>
</template>

<script>
import HeaderIcon from "@/components/UI/HeaderIcon.vue";
import { mapState } from "vuex";
import GameButton from "@/components/header/GameButton.vue";
import RawAwardsCount from "@/components/payments/awards/raw-awards-count/RawAwardsCount.vue";
export default {
  components: {
    RawAwardsCount,
    HeaderIcon,
  },
  data() {
    return {
      toRoles: ["to", "supervisor_to"],
      paymentsRoles: [
        "manager",
        "rgp",
        "admin",
        "fin_monitor",
        "sale_director",
        "director",
      ],
      awardsCounterRole: ["admin", "director", "sale_director"],
      addressSearchRoles: [
        "admin",
        "manager",
        "bonus_product_specialist",
        "rgp",
        "sale_director",
      ],
      gameRoles: ["admin", "rgp", "sale_director", "director"],
      hrRoles: ["hr"],
      statisticRoles: ["admin", "sale_director"],
    };
  },
  computed: {
    ...mapState({
      user: state => state.authModule.user,
      userRole: state => state.authModule.userRole,
    }),
  },
};
</script>
