import { documentsApi } from "@/api/documents/documentsApi";

export default {
  namespaced: true,
  state() {
    return {
      documentsList: [],
      companiesList: [],
      keys: [],
    };
  },
  getters: {
    getDocumentsList(state) {
      return state.documentsList;
    },
    getCompaniesList(state) {
      return state.companiesList;
    },
    getKeys(state) {
      return state.keys.length
        ? state.keys.map(k =>
            k.hasOwnProperty("value")
              ? { ...k, description: k.value }
              : { ...k }
          )
        : [];
    },
  },
  mutations: {
    setDocumentsList(state, payload) {
      state.documentsList = payload;
    },
    setCompaniesList(state, payload) {
      state.companiesList = payload;
    },
    setKeys(state, payload) {
      state.keys = payload;
    },
  },
  actions: {
    fetchDocumentsList({ commit }) {
      return documentsApi
        .fetchDocuments()
        .then(response => {
          commit("setDocumentsList", response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    },

    fetchCompaniesList({ commit }) {
      return documentsApi
        .fetchCompanies()
        .then(response => {
          commit("setCompaniesList", response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    },

    fetchKeys({ commit }, { listType, id }) {
      return documentsApi
        .fetchKeys(listType, id)
        .then(response => {
          commit("setKeys", response.data.data);
          return response.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    updateKey({ commit }, data) {
      return documentsApi
        .updateKey(data)
        .then(res => {
          alert("Ключ обновлен");
        })
        .catch(error => {
          console.log(error);
        });
    },

    updateDocumentOrCompany({ commit }, data) {
      const { listType } = data;
      const msg =
        listType === "documents"
          ? "Договор обновлен"
          : "Компания компания обновлена";
      return documentsApi
        .updateDocumentOrCompany(data)
        .then(res => {
          alert(msg);
        })
        .catch(error => {
          console.log(error);
        });
    },

    removeKey({ commit }, data) {
      let isOk = confirm("Вы уверены, что хотите удалить ключевое слово?");

      if (isOk) {
        return documentsApi
          .removeKey(data)
          .then(res => {
            alert("Ключ удален");
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    addKeys({ commit }, data) {
      return documentsApi
        .addKeys(data)
        .then(res => {
          alert("Ключевые слова добавлены");
        })
        .catch(error => {
          console.log(error);
        });
    },

    addCompany({ commit }, name) {
      return documentsApi
        .addCompany(name)
        .then(res => {
          alert("Компания добавлена");
        })
        .catch(error => {
          console.log(error);
        });
    },

    removeDocumentOrCompany({ commit }, data) {
      let isOk = confirm("Вы уверены, что хотите продолжить?");
      const { listType } = data;
      const msg =
        listType === "documents" ? "Договор удален" : "Компания удалена";
      if (isOk) {
        return documentsApi
          .removeDocumentOrCompany(data)
          .then(res => {
            alert(msg);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },
};
