import { DefaultApiInstance } from "../index";
import qs from "qs";

const createDateFilter = (date, key) => {
  const { dateFrom, dateTo } = date;
  const filters = [
    {
      type: "between",
      key: key,
      value: {
        gte: [dateFrom + " 00:00:00"],
        lte: [dateTo + " 23:59:59"],
      },
    },
  ];

  return qs
    .stringify({ filters })
    .replaceAll("%5B", "[")
    .replaceAll("%5D", "]");
};

export const StatisticApi = {
  fetchRedFinanceRisks(role) {
    const url = `${role}/orders/digest/red-finance`;
    return DefaultApiInstance.get(url);
  },
  fetchRedHeaderData(role) {
    const url = `${role}/orders/digest/red-header-statistic`;
    return DefaultApiInstance.get(url);
  },
  fetchRedContracts(role) {
    const url = `${role}/orders/digest/red-contract`;
    return DefaultApiInstance.get(url);
  },
  fetchSoldHeaders(role, period) {
    const queryParams = createDateFilter(period, "signed_at");
    const url = `${role}/orders/digest/sold-statistic/header-statistic?${queryParams}`;
    return DefaultApiInstance.get(url);
  },
  fetchSoldTable(role, period) {
    const queryParams = createDateFilter(period, "signed_at");
    const url = `${role}/orders/digest/sold-statistic/table?${queryParams}`;
    return DefaultApiInstance.get(url);
  },
  fetchSoldChart(role, period) {
    const queryParams = createDateFilter(period, "signed_at");
    const url = `${role}/orders/digest/sold-statistic/chart?${queryParams}`;
    return DefaultApiInstance.get(url);
  },
  fetchLostAndWithheld(role, period) {
    const queryParams = createDateFilter(period, "start_cancel_at");
    const url = `${role}/orders/digest/start-cancel-at/table?${queryParams}`;
    return DefaultApiInstance.get(url);
  },
  fetchManagersData(role, period) {
    const queryParams = createDateFilter(period, "signed_at");
    const url = `${role}/orders/digest/manager-statistic?${queryParams}`;
    return DefaultApiInstance.get(url);
  },
};
