import Vue from "vue";
import Vuex from "vuex";
import ordersModule from "./modules/orders/ordersModule";
import orderModule from "./modules/order/orderModule";
import authModule from "./modules/auth/authModule";
import dictionaryModule from "./modules/dictionary/dictionaryModule";
import usersModule from "./modules/users/usersModule";
import filtersModule from "./modules/filters/filtersModule";
import overlayModule from "./modules/overlay/overlayModule";
import commentsModule from "./modules/comments/commentsModule";
import statisticModule from "./modules/statistic/statisticModule";
import topDigestModule from "./modules/top-digest/topDigestModule";
import topDigestAnalyticModule from "./modules/top-digest-analytic/topDigestAnalyticModule";
import modalModule from "@/store/modules/modal/modalModule";
import planHistoryModule from "@/store/modules/plan-history/planHistoryModule";
import topDigestPlanModule from "@/store/modules/top-digest-plan/topDigestPlanModule";
import toModule from "@/store/modules/to/toModule";
import addressSearchModule from "@/store/modules/address-search/addressSearchModule";
import paymentsModule from "@/store/modules/payments/paymentsModule";
import gameModule from "@/store/modules/game/gameModule";
import colorsModule from "@/store/modules/colors/colorsModule";
import priceListModule from "@/store/modules/price-list/price-list-module";
import discountModule from "@/store/modules/discount/discountModule";
import postsModule from "@/store/modules/posts/postsModule";
import rgpPaymentsModule from "@/store/modules/rgp-payments/rgpPaymentsModule";
import awardsModule from "@/store/modules/awards/awardsModule";
import managerPaymentsModule from "@/store/modules/manager-payments/managerPaymentsModule";
import documentsModule from "@/store/modules/documents/documentsModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ordersModule,
    orderModule,
    authModule,
    dictionaryModule,
    usersModule,
    filtersModule,
    overlayModule,
    commentsModule,
    statisticModule,
    topDigestModule,
    topDigestAnalyticModule,
    modalModule,
    planHistoryModule,
    topDigestPlanModule,
    toModule,
    addressSearchModule,
    paymentsModule,
    gameModule,
    colorsModule,
    priceListModule,
    discountModule,
    postsModule,
    rgpPaymentsModule,
    awardsModule,
    managerPaymentsModule,
    documentsModule,
  },
});
