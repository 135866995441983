<template>
  <div class="order">
    <div class="main-page__header header">
      <div class="container">
        <div class="header__inner">
          <div class="header__info">
            <div class="header__logo">
              <HeaderIcon name="logo" />
            </div>
            <HeaderLinks />
          </div>
          <div class="header__actions"></div>
        </div>
        <!--        <StatisticHeader v-if="digestRoles.includes(userRole)" />-->
        <AdminStatisticTabs />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import HeaderLinks from "@/components/header/HeaderLinks.vue";
import HeaderIcon from "@/components/UI/HeaderIcon.vue";
import AdminStatisticTabs from "@/components/admin/statistic-tabs/AdminStatisticTabs.vue";
export default {
  name: "admin-statistic-layout",
  components: {
    AdminStatisticTabs,
    HeaderLinks,
    HeaderIcon,
  },
  data() {
    return {
      toRoles: ["to", "supervisor_to"],
      digestRoles: ["admin", "sale_director"],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    userRole() {
      return this.$store.state.authModule.userRole;
    },
    getQueryParam() {
      return this.$route.name;
    },
    getUserName() {
      return this.$store.state.authModule.user.name;
    },
  },
  methods: {
    navigateToDigest(e) {
      this.$router.push(e.target.dataset.digest);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.header__actions-username {
  font-weight: 600;
  font-size: 24px;
}
</style>
