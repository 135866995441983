import { DefaultApiInstance } from "@/api";
import qs from "qs";

export const AwardsApi = {
  fetchAwards(filters) {
    let url = "/awards";

    let params = qs
      .stringify({ filters })
      .replaceAll("%5B", "[")
      .replaceAll("%5D", "]");

    url += `?${params}`;

    return DefaultApiInstance.get(url);
  },
};
