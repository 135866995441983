<template>
  <button
    v-if="roles.includes(userRole)"
    @click="getOrdersInExcelFile"
    class="excel-button"
  >
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.19987 14.3999H2.99987C2.11621 14.3999 1.39987 13.6836 1.39987 12.7999L1.39993 3.19997C1.39994 2.31631 2.11628 1.59998 2.99993 1.59998H10.2001C11.0838 1.59998 11.8001 2.31632 11.8001 3.19998V7.59997M12.6001 12.7716L10.9639 14.4M10.9639 14.4L9.40012 12.8452M10.9639 14.4V10.4"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    Выгрузить в Excel
  </button>
</template>

<script>
import { mapState } from "vuex";
import { DefaultApiInstance } from "@/api";
import { Date } from "core-js";
export default {
  name: "ExcelButton",
  data() {
    return {
      roles: ["admin", "director"],
    };
  },
  computed: {
    userRole() {
      return this.$store.state.authModule.userRole;
    },
    getFilters() {
      return this.$store.getters["ordersModule/getFiltersQueryString"];
    },
    getScope() {
      return this.$store.getters["ordersModule/getScope"];
    },
    getIsOrdersLoading() {
      return this.$store.getters["paymentsModule/getIsOrdersLoading"];
    },
    getSearch() {
      return this.$store.getters["ordersModule/getSearchQuery"];
    },
  },
  methods: {
    getOrdersInExcelFile() {
      const scope = this.getScope.length ? this.getScope : "";
      const filters = this.getFilters.length ? this.getFilters : "";
      const search = this.getSearch.length ? this.getSearch : "";
      let url = `/${this.userRole}/orders/excel?${scope}&${filters}&search=${search}`;

      this.$store.commit("ordersModule/setIsOrdersLoading", true);

      return DefaultApiInstance({
        url,
        method: "GET",
        responseType: "blob",
      })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `orders_${new Date()
              .toISOString()
              .slice(0, 19)
              .replace("T", " ")}.xlsx`
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(err => alert("Что-то пошло не так"))
        .finally(() =>
          this.$store.commit("ordersModule/setIsOrdersLoading", false)
        );
    },
  },
};
</script>
