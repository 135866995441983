import { AdminApi } from "@/api/adminApi";
import { apiUrl } from "../../../../config.json";
import { DefaultApiInstance } from "@/api";
import { DictionaryApi } from "@/api/dictionaryApi";
import {
  MANAGERS_TYPE,
  MANAGERS_TYPE_KEY,
  ROLES_KEY,
} from "@/utils/localStorage";

export default {
  namespaced: true,
  state: () => ({
    historyTypes: JSON.parse(localStorage.getItem("historyTypes")) || null,
    roles: JSON.parse(localStorage.getItem(ROLES_KEY)) || null,
    userStatuses: JSON.parse(localStorage.getItem("userStatuses")) || null,
    clientRequestType:
      JSON.parse(localStorage.getItem("clientRequestType")) || null,
    ordersStatuses: JSON.parse(localStorage.getItem("ordersStatuses")) || null,
    orderStatus: "",

    regionsCollections: [],
    usersByRole: [],
    mppStatuses: [],
    orderTypes: JSON.parse(localStorage.getItem("orderTypes")) || [],
    managersType: JSON.parse(localStorage.getItem(MANAGERS_TYPE_KEY)) || null,
  }),
  actions: {
    async historyTypes({ commit }) {
      await fetch(`${apiUrl}/dictionary/order/history/types`)
        .then(res => res.json())
        .then(data => commit("updateHistoryTypes", data));
    },

    async userStatuses({ commit }) {
      await fetch(`${apiUrl}/dictionary/user/statuses`)
        .then(res => res.json())
        .then(data => commit("updateUserStatuses", data));
    },
    async clientRequestType({ commit }) {
      await fetch(`${apiUrl}/dictionary/client/requisite/types`)
        .then(res => res.json())
        .then(data => commit("updateClientRequestType", data));
    },
    async ordersStatuses({ commit }) {
      await fetch(`${apiUrl}/dictionary/order/statuses`)
        .then(res => res.json())
        .then(data => commit("updateOrdersStatuses", data));
    },
    async mppStatuses({ commit }) {
      await fetch(`${apiUrl}/dictionary/order/status_mpp`)
        .then(res => res.json())
        .then(data => commit("updateMppStatuses", data));
    },

    async fetchRegions({ commit, state }, role) {
      return await AdminApi.getRegions(role)
        .then(res => {
          state.totalQty = res.data.meta.per_page * res.data.meta.last_page;
        })
        .then(() => {
          return AdminApi.getAllRegions(role, state.totalQty).then(res => {
            commit("setRegions", res.data);
          });
        });
    },

    fetchRoles({ commit }) {
      return DictionaryApi.fetchRoles()
        .then(res => {
          commit("updateRoles", res.data);
        })
        .catch(e => console.log(e));
    },

    fetchManagerType({ state, commit }) {
      return DictionaryApi.fetchManagersType()
        .then(res => {
          commit("setManagersType", res.data);
        })
        .catch(e => console.log(e));
    },

    fetchOrderTypes({ commit, state }) {
      const url = `/dictionary/order/types`;
      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setOrderTypes", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
  mutations: {
    updateHistoryTypes(state, types) {
      state.historyTypes = types;
      localStorage.setItem("historyTypes", JSON.stringify(types));
    },
    updateRoles(state, types) {
      state.roles = types;
      localStorage.setItem(ROLES_KEY, JSON.stringify(types));
    },
    updateUserStatuses(state, types) {
      state.userStatuses = types;
      localStorage.setItem("userStatuses", JSON.stringify(types));
    },
    updateClientRequestType(state, types) {
      state.clientRequestType = types;
      localStorage.setItem("clientRequestType", JSON.stringify(types));
    },
    updateOrdersStatuses(state, types) {
      state.ordersStatuses = types;
      localStorage.setItem("ordersStatuses", JSON.stringify(types));
    },
    updateMppStatuses(state, types) {
      state.mppStatuses = types;
      localStorage.setItem("mppStatuses", JSON.stringify(types));
    },

    setRegions(state, types) {
      state.regionsCollections = types;
    },

    setManagersType(state, types) {
      const key = MANAGERS_TYPE_KEY;
      localStorage.setItem(key, JSON.stringify(types));
      state.managersType = types;
    },

    setOrderTypes(state, types) {
      state.orderTypes = types;
      localStorage.setItem("orderTypes", JSON.stringify(types));
    },

    deleteTypes(state) {
      state.historyTypes = null;
      state.roles = null;
      state.userStatuses = null;
      state.clientRequestType = null;
      state.ordersStatuses = null;
      state.orderStatus = null;
      state.orderTypes = [];
      localStorage.removeItem("historyTypes");
      localStorage.removeItem("roles");
      localStorage.removeItem("userStatuses");
      localStorage.removeItem("clientRequestType");
      localStorage.removeItem("ordersStatuses");
    },
  },
  getters: {
    getUserRoles(state) {
      return state.rolesCollections;
    },
    getOrderStatuses(state) {
      return state.ordersStatuses;
    },
    getStatusMpp(state) {
      return Object.keys(state.mppStatuses).map(o => ({
        key: o,
        value: o,
      }));
    },
    getCounter1cStatuses() {
      return [
        { key: ["wait_action_1c"], value: "Ожидает действия" },
        { key: ["sent", "wait_sent"], value: "Отправлен в 1С" },
        { key: ["loaded"], value: "Загружен в 1С" },
        { key: ["check_failed", "failed_load"], value: "Ошибка" },
      ];
    },
    getCounterToStatuses() {
      return [
        { key: ["wait_action_to"], value: "Ожидает действия" },
        { key: ["to_block"], value: "Блок ТО" },
        { key: ["to_success"], value: "Одобрено" },
      ];
    },
    getFinishStatuses() {
      return [
        { key: "success_finished", value: "Закрыт" },
        { key: "cancel_finished", value: "Расторгнут" },
      ];
    },
    getOrderTypes(state) {
      return Object.entries(state.orderTypes).map(([key, value]) => ({
        value:
          value === "Готовая кухня"
            ? "Кухня на заказ"
            : value === "Готовые шкафы"
            ? "Шкаф готовый"
            : "Шкаф на заказ",
        key: key,
      }));
    },

    getManagersType(state) {
      const managersType = state.managersType;
      return Object.entries(managersType).map(([key, value]) => ({
        label: value,
        value: key,
      }));
    },

    getRoles(state) {
      const roles = state.roles.data;
      return roles.map(r => {
        return { label: r.name, value: r.id };
      });
    },
  },
};
