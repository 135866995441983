import { FiltersApi } from "@/api/filtersApi";
import { apiUrl } from "../../../../config.json";

export default {
  namespaced: true,
  state() {
    return {
      apiUrl: apiUrl,
      params: {
        _q: "test",
      },
      selectedClients: [],
      searchedClients: [],
      searchedManagers: [],
      searchedRegions: [],
      searchedRgp: [],
      selectedClientsId: [],
      userRole: localStorage.getItem("userRole") || null,
      selected: false,
      isVisible: false,
      clientSelected: false,
      // sort
      sortValue: localStorage.getItem("sortValue") || null,
      sortType: localStorage.getItem("sortType") || null,
      isSortedFlag: localStorage.getItem("isSortedFlag") || null,
      // filters
      statusFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersStatus") !== null ? true : false,
      },
      statusMppFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersMppStatus") !== null ? true : false,
      },
      timerFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersTimer") !== null ? true : false,
      },
      signedAtFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersSignedAt") !== null ? true : false,
      },
      clientFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filterClient") !== null ? true : false,
      },
      counterToFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersTo") !== null ? true : false,
      },
      toSpecialistFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersToSpecialist") !== null ? true : false,
      },
      counterOneCFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filters1c") !== null ? true : false,
      },
      managersFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersManager") !== null ? true : false,
      },
      regionFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersRegion") !== null ? true : false,
      },
      rgpFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersRgp") !== null ? true : false,
      },
      finishFilter: {
        isVisible: false,
        isFilterSelect:
          localStorage.getItem("filtersFinish") !== null ? true : false,
      },
    };
  },
  getters: {},
  mutations: {
    // add url params
    setSelectedClients(state, client) {
      state.selectedClients = client;
    },
    setSearchedClients(state, clients) {
      state.searchedClients = clients;
    },
    setSearchedManagers(state, clients) {
      state.searchedManagers = clients;
    },
    setSearchedRegions(state, regions) {
      state.searchedRegions = regions;
    },
    setSearchedRgp(state, rgps) {
      state.searchedRgp = rgps;
    },

    setSelectedClientsId(state, id) {
      state.selectedClientsId = id;
    },
    clearSearchData(state) {
      state.selectedClients = [];
      state.searchedClients = [];
      state.selectedClientsId = [];
      state.searchedManagers = [];
      state.searchedRegions = [];
      state.searchedRgp = [];
    },
    disableSelect(state) {
      state.selected = true;
    },
    // status
    showStatusFilter(state) {
      state.statusFilter.isVisible = true;
    },
    hideStatusFilter(state) {
      state.statusFilter.isVisible = false;
    },
    setStatusFilterIcon(state, boolean) {
      state.statusFilter.isFilterSelect = boolean;
    },
    // statusMpp
    showStatusMppFilter(state) {
      state.statusMppFilter.isVisible = true;
    },
    hideStatusMppFilter(state) {
      state.statusMppFilter.isVisible = false;
    },
    setStatusMppFilterIcon(state, boolean) {
      state.statusMppFilter.isFilterSelect = boolean;
    },
    // client
    showClientFilter(state) {
      state.clientFilter.isVisible = true;
    },
    hideClientFilter(state) {
      state.clientFilter.isVisible = false;
    },
    setClientFilterIcon(state, boolean) {
      state.clientFilter.isFilterSelect = boolean;
    },
    // timer
    showTimerFilter(state) {
      state.timerFilter.isVisible = true;
    },
    hideTimerFilter(state) {
      state.timerFilter.isVisible = false;
    },
    setTimerFilterIcon(state, boolean) {
      state.timerFilter.isFilterSelect = boolean;
    },
    // to
    showCounterToFilter(state) {
      state.counterToFilter.isVisible = true;
    },
    hideCounterToFilter(state) {
      state.counterToFilter.isVisible = false;
    },
    setCounterToIcon(state, boolean) {
      state.counterToFilter.isFilterSelect = boolean;
    },

    // to specialist

    showToSpecialistFilter(state) {
      state.toSpecialistFilter.isVisible = true;
    },
    hideToSpecialistFilter(state) {
      state.toSpecialistFilter.isVisible = false;
    },
    setToSpecialistIcon(state, boolean) {
      state.toSpecialistFilter.isFilterSelect = boolean;
    },
    // one c
    showCounterOneCFilter(state) {
      state.counterOneCFilter.isVisible = true;
    },
    hideCounterOneCFilter(state) {
      state.counterOneCFilter.isVisible = false;
    },
    setCounterOneCIcon(state, boolean) {
      state.counterOneCFilter.isFilterSelect = boolean;
    },
    // managers
    showManagerFilter(state) {
      state.managersFilter.isVisible = true;
    },
    hideManagerFilter(state) {
      state.managersFilter.isVisible = false;
    },
    setManagerFilterIcon(state, boolean) {
      state.managersFilter.isFilterSelect = boolean;
    },
    // region
    showRegionFilter(state) {
      state.regionFilter.isVisible = true;
    },
    hideRegionFilter(state) {
      state.regionFilter.isVisible = false;
    },
    setRegionFilterIcon(state, boolean) {
      state.regionFilter.isFilterSelect = boolean;
    },
    // rgp
    showRgpFilter(state) {
      state.rgpFilter.isVisible = true;
    },
    hideRgpFilter(state) {
      state.rgpFilter.isVisible = false;
    },
    setRgpFilterIcon(state, boolean) {
      state.rgpFilter.isFilterSelect = boolean;
    },
    // finish
    showFinishFilter(state) {
      state.finishFilter.isVisible = true;
    },
    hideFinishFilter(state) {
      state.finishFilter.isVisible = false;
    },
    setFinishFilterIcon(state, boolean) {
      state.finishFilter.isFilterSelect = boolean;
    },
    // signed at
    showSignedAtFilter(state) {
      state.signedAtFilter.isVisible = true;
    },
    hideSignedAtFilter(state) {
      state.signedAtFilter.isVisible = false;
    },
    setSignedAtFilterIcon(state, boolean) {
      state.signedAtFilter.isFilterSelect = boolean;
    },

    // sort

    setSortValue(state, sortValue) {
      state.sortValue = sortValue;
      localStorage.setItem("sortValue", sortValue);
      localStorage.setItem("isSortedFlag", "true");
    },
    setSortType(state, sortType) {
      state.sortType = sortType;
      localStorage.setItem("sortType", sortType);
      localStorage.setItem("isSortedFlag", "true");
    },
    setSortedFlag(state, sortedFlag) {
      state.isSortedFlag = sortedFlag;
    },

    //   снять выделение с иконок фильтра

    resetIsFilterSelect(state) {
      Object.entries(state).forEach(([key, value]) => {
        if (
          value &&
          key.includes("Filter") &&
          value.hasOwnProperty("isFilterSelect")
        ) {
          value.isFilterSelect = false;
        }
      });
    },
  },

  actions: {
    async fetchClients({ commit }, { role, searchQuery }) {
      return await FiltersApi.getSearchedClients(role, searchQuery).then(
        res => {
          commit("setSearchedClients", res.data.data);
        }
      );
    },
    async fetchRegions({ commit }, { role, searchQuery }) {
      return await FiltersApi.getSearchedRegions(role, searchQuery).then(
        res => {
          commit("setSearchedRegions", res.data.data);
        }
      );
    },
    async fetchRgp({ commit }, { role, searchQuery }) {
      return await FiltersApi.getSearchedRgp(role, searchQuery).then(res => {
        commit("setSearchedRgp", res.data.data);
      });
    },
    async fetchManagers({ commit }, { role, searchQuery }) {
      if (role === "rgp") {
        return await FiltersApi.getRgpSearchedManagers(role, searchQuery).then(
          res => {
            commit("setSearchedManagers", res.data.data);
          }
        );
      } else {
        return await FiltersApi.getSearchedManagers(role, searchQuery).then(
          res => {
            commit("setSearchedManagers", res.data.data);
          }
        );
      }
    },
  },
};
