export default {
  state() {
    return {
      plan: null,
      orders: [],
    };
  },
  getters: {
    getPlan(state) {
      return state.plan;
    },
    getOrders(state) {
      return state.orders;
    },
  },
  mutations: {},
  actions: {},
};
