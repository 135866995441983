import qs from "qs";
import { DefaultApiInstance } from "@/api";
import _groupBy from "lodash/groupBy";
import _uniqBy from "lodash/uniqBy";
import { saleTable } from "@/api/saleTable";
import { AdminApi } from "@/api/adminApi";
import { AuthApi } from "@/api/authApi";

export default {
  namespaced: true,
  state() {
    return {
      periods: [
        {
          id: 0,
          name: "Январь",
          value: null,
        },
        {
          id: 1,
          name: "Февраль",
          value: null,
        },
        {
          id: 2,
          name: "Март",
          value: null,
        },
        {
          id: 3,
          name: "Апрель",
          value: null,
        },
        {
          id: 4,
          name: "Май",
          value: null,
        },
        {
          id: 5,
          name: "Июнь",
          value: null,
        },
        {
          id: 6,
          name: "Июль",
          value: null,
        },
        {
          id: 7,
          name: "Август",
          value: null,
        },
        {
          id: 8,
          name: "Сентябрь",
          value: null,
        },
        {
          id: 9,
          name: "Октябрь",
          value: null,
        },
        {
          id: 10,
          name: "Ноябрь",
          value: null,
        },
        {
          id: 11,
          name: "Декабрь",
          value: null,
        },
      ],
      selectedMonthId: localStorage.getItem("payments-month") || null,
      selectedMonth: JSON.parse(localStorage.getItem("selected-month")) || null,
      orders: JSON.parse(localStorage.getItem("paymentsOrders")) || [],
      newbieOrders: [],
      isDatePickerVisible: false,
      ordersForExport: [],
      rgpOrdersForExport: [],
      isOrdersLoading: false,
      isRgpVisible: false,
      isManagerVisible: false,
      isOrderVisible: false,
      //   refactror
      isTableOpen: false,
      //   for additional bonuses
      additionalBonuses: {},
      //   for grade bonus
      accountData: null,
      //   for admin
      currentUserId: null,
      ratingData: [],
      experienceOrders: [],
      notSoldOrders: [],
      notSoldWithTransits: [],

      //   for rgp sums

      markup: [],
      percent: [],
      bonus: [],
      additionalSum: [],
      newbieSalaryBonus: [],
      awardSalaryBonus: [],
      experienceBonus: [],
      closetOrdersSalaryBonus: [],
      transitsSum: [],

      //   for rgp payments
      kpiKitchen: null,
      kpiCloset: null,
      kpiSentKg: null,
      rgpPaymentsPlan: [],
      kitchenHistoryRgpPaymentsPlan: [],
      closetHistoryRgpPaymentsPlan: [],
      managersWith3and4grade: [],

      prizeFund: 0,

      overdueOrders: [],
      isOrdersLoaded: false,

      saleTableData: [],
      managersCertainRgp: [],
    };
  },
  getters: {
    getUserRole({ getters, rootGetters }) {
      return rootGetters["authModule/getUserRole"];
    },
    getOrders(state) {
      return state.orders?.data || null;
    },
    getIsOrdersLoaded(state) {
      return state.isOrdersLoaded;
    },
    getNewbieOrders(state) {
      return state.newbieOrders;
    },

    getNotSoldWithTransitsOrders(state) {
      const orders = state.notSoldWithTransits;
      return _groupBy(orders, "manager_id");
    },

    getOrdersGroupByManager(state, getters) {
      return _groupBy(getters.getOrders, "manager.id");
    },
    getIsDatePickerVisible(state) {
      return state.isDatePickerVisible;
    },
    getOrdersForExport(state) {
      return state.ordersForExport;
    },
    getRgpOrdersForExport(state) {
      return state.rgpOrdersForExport;
    },
    getIsOrdersLoading(state) {
      return state.isOrdersLoading;
    },
    getSelectedMonthId(state) {
      return state.selectedMonthId;
    },

    getIsTableOpen(state) {
      return state.isTableOpen;
    },

    getUserRatingData(state) {
      return state.ratingData?.reduce((acc, el) => {
        acc[el.user_id] = el;
        return acc;
      }, {});
    },

    getSupervisorsMarkupSum(state) {
      return state.markup;
    },

    getSupervisorsPercentSum(state) {
      return state.percent;
    },

    getSupervisorsBonusSum(state) {
      return state.bonus;
    },

    getSupervisorAdditionalSum(state) {
      return state.additionalSum;
    },

    getNewbieSalaryBonus(state) {
      return state.newbieSalaryBonus;
    },

    getSupervisorAwardsBonus(state) {
      return state.awardSalaryBonus;
    },

    getExperienceSalaryBonus(state) {
      return state.experienceBonus;
    },

    getClosetOrdersSalaryBonus(state) {
      return state.closetOrdersSalaryBonus;
    },

    getTransitsSum(state) {
      return state.transitsSum;
    },

    getRgpPaymentsPlan(state) {
      const planGroupByUserId = _groupBy(state.rgpPaymentsPlan, "user.name");

      return planGroupByUserId;
    },

    getKitchenHistoryPlan(state) {
      const kitchenOrders = state.kitchenHistoryRgpPaymentsPlan;
      return _groupBy(kitchenOrders, "user.name");
    },

    getClosetHistoryPlan(state) {
      const closetOrders = state.closetHistoryRgpPaymentsPlan;
      return _groupBy(closetOrders, "user.name");
    },

    getSelectedMonth(state) {
      return state.selectedMonth;
    },

    getSumOfKitchenPlan(state) {
      const plans = state.kitchenHistoryRgpPaymentsPlan?.filter(
        p => p.user.status === "enabled"
      );
      const uniqPlans = _uniqBy(plans, "user_id");
      return uniqPlans.reduce((acc, plan) => {
        const value = Number(plan.value);
        acc += value;
        return acc;
      }, 0);
    },

    getSumOfClosetPlan(state) {
      const plans = state.closetHistoryRgpPaymentsPlan?.filter(
        p => p.user.status === "enabled"
      );
      const uniqPlans = _uniqBy(plans, "user_id");
      return uniqPlans.reduce((acc, plan) => {
        const value = Number(plan.value);
        acc += value;
        return acc;
      }, 0);
    },

    getSumOfKitchenOrders(state) {
      const orders = state.orders?.data;
      const kitchenOrders = orders?.filter(o => o.type === "kitchen");
      return kitchenOrders.reduce((sum, o) => {
        const fullPrice = o.full_price ? Number(o.full_price) : 0;
        const deliveryCost = o.delivery_cost ? Number(o.delivery_cost) : 0;
        const salaryValue = fullPrice - deliveryCost;
        sum += salaryValue;
        return sum;
      }, 0);
    },

    getSumOfClosetOrders(state) {
      const orders = state.orders?.data;
      const closetOrders = orders?.filter(o => o.type === "custom_closet");
      return closetOrders.reduce((sum, o) => {
        const fullPrice = o.full_price ? Number(o.full_price) : 0;
        const deliveryCost = o.delivery_cost ? Number(o.delivery_cost) : 0;
        const salaryValue = fullPrice - deliveryCost;
        sum += salaryValue;
        return sum;
      }, 0);
    },

    getAllRgpId(state, getters) {
      const orders = getters.getOrders;
      const uniqOrders = _uniqBy(orders, "manager.supervisors[0].id");
      return uniqOrders.map(o => o.manager.supervisors[0].id);
    },

    getRgpQueryParam(_, getters) {
      const ids = getters.getAllRgpId;
      const paramValue = `supervisors`;
      const paramFilter = ids.map(e => `${paramValue}[]=${e}`).join("&");
      return `?${paramFilter}&grades[]=4&grades[]=5&with[]=user.supervisors&`;
    },

    getManagersWith3and4Grade(state) {
      return state.managersWith3and4grade;
    },

    getManagersCount(_, getters) {
      const orders = getters.getManagersWith3and4Grade;
      return orders.length ? _uniqBy(orders, "user_id").length : 0;
    },

    getPrizeFund(state) {
      return state.prizeFund;
    },

    getOverdueOrders(state) {
      return state.overdueOrders;
    },

    getExperienceOrders(state) {
      return state.experienceOrders;
    },
    getNotSoldOrders(state) {
      return state.notSoldOrders;
    },
    getSaleTable(state) {
      return state.saleTableData;
    },
    getManagersCertainRgp(state) {
      return state.managersCertainRgp;
    },
  },

  mutations: {
    setIsOrdersLoaded(state, boolean) {
      state.isOrdersLoaded = boolean;
    },
    setOrders(state, orders) {
      state.orders = orders;
      localStorage.setItem("paymentsOrders", JSON.stringify(orders));
    },
    setNewbieOrders(state, orders) {
      state.newbieOrders = orders;
    },

    setNotSoldWithTransits(state, orders) {
      state.notSoldWithTransits = orders;
    },

    setIsDatePickerVisible(state, isVisible) {
      state.isDatePickerVisible = isVisible;
    },
    setOrdersForExport(state, order) {
      state.ordersForExport.push(order);
    },
    setRgpOrdersForExport(state, order) {
      state.rgpOrdersForExport.push(order);
    },
    setIsOrdersLoading(state, isLoading) {
      state.isOrdersLoading = isLoading;
    },
    clearOrdersForExport(state) {
      state.ordersForExport = [];
      state.rgpOrdersForExport = [];
    },

    setIsTableOpen(state) {
      state.isTableOpen = !state.isTableOpen;
    },
    setSelectedMonthId(state, id) {
      state.selectedMonthId = id;
      localStorage.setItem("payments-month", id);
    },
    setAccountData(state, data) {
      state.accountData = data;
    },
    setRatingData(state, data) {
      state.ratingData = data;
    },
    setCurrentUserId(state, data) {
      state.currentUserId = data;
      localStorage.setItem("currentUserId", data);
    },

    // for rgp sums

    setMarkupSum(state, payload) {
      state.markup.push(payload);
    },

    setPercentSum(state, sum) {
      state.percent.push(sum);
    },

    setAdditionalSum(state, sum) {
      state.additionalSum.push(sum);
    },

    setTransitsSum(state, sum) {
      state.transitsSum.push(sum);
    },

    setBonusSum(state, sum) {
      state.bonus.push(sum);
    },

    setNewbieSalaryBonus(state, sum) {
      state.newbieSalaryBonus.push(sum);
    },

    setAwardSalaryBonus(state, sum) {
      state.awardSalaryBonus.push(sum);
    },

    setExperienceSalaryBonus(state, sum) {
      state.experienceBonus.push(sum);
    },

    setClosetOrdersSalaryBonus(state, sum) {
      state.closetOrdersSalaryBonus.push(sum);
    },

    clearSumValues(state) {
      state.bonus = [];
      state.percent = [];
      state.markup = [];
      state.additionalSum = [];
      state.newbieSalaryBonus = [];
      state.awardSalaryBonus = [];
      state.closetOrdersSalaryBonus = [];
      state.experienceBonus = [];
      state.transitsSum = [];
    },

    setKPIKitchen(state, value) {
      state.kpiKitchen = value;
    },
    setKPICloset(state, value) {
      state.kpiCloset = value;
    },
    setOverdueKpi(state, value) {
      state.kpiSentKg = value;
    },

    setPlans(state, value) {
      state.rgpPaymentsPlan = value;
    },

    setSelectedMonth(state, value) {
      state.selectedMonth = value;
      localStorage.setItem("selected-month", JSON.stringify(value));
    },

    setHistoryKitchenPlan(state, value) {
      state.kitchenHistoryRgpPaymentsPlan = value;
    },

    setHistoryClosetPlan(state, value) {
      state.closetHistoryRgpPaymentsPlan = value;
    },

    setPrizeFund(state, value) {
      state.prizeFund = value;
    },

    setManagersWith3and4grade(state, value) {
      state.managersWith3and4grade = value;
    },

    setOverdueOrders(state, orders) {
      state.overdueOrders = orders;
    },

    setExperienceOrders(state, orders) {
      state.experienceOrders = orders;
    },

    setNotSoldOrders(state, orders) {
      state.notSoldOrders.push(orders);
    },

    setSaleTableData(state, data) {
      state.saleTableData = data;
    },

    setManagersCertainRgp(state, data) {
      state.managersCertainRgp = data;
    },
  },

  actions: {
    fetchOrders({ state, getters, commit }, { userRole, from, to, isRgp }) {
      commit("clearOrdersForExport");
      commit("setOrders", null);
      let filters = [];
      let url = `${userRole}/orders?with[]=bonuses.bonusProduct&with[]=lastOrderStatusData&with[]=manager.supervisors&without_pagination=1&sort=manager_region|asc&scope[]=add_is_provide_overdubs&`;

      !isRgp
        ? filters.push({
            type: "in",
            key: "payment_sign",
            value: ["to_payoff", "paid"],
          })
        : filters.push({
            type: "eq",
            key: "status_mpp",
            value: ["Продан"],
          });

      if (isRgp) {
        filters.push({
          type: "except",
          key: "status",
          value: ["cancel_finished"],
        });
      }

      !isRgp
        ? filters.push({
            type: "between",
            key: "pre_bonus_at",
            value: { gte: `${from} 00:00:00`, lte: `${to} 23:59:59` },
          })
        : filters.push({
            type: "between",
            key: "signed_at",
            value: { gte: `${from} 00:00:00`, lte: `${to} 23:59:59` },
          });

      let params = qs
        .stringify({ filters })
        .replaceAll("%5B", "[")
        .replaceAll("%5D", "]");

      url += params;

      DefaultApiInstance.get(url)
        .then(res => {
          commit("setOrders", res.data);
          return res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchAccountData({ state, commit }) {
      const url = `/account`;
      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setAccountData", res.data);
          return res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchRatingData({ commit }) {
      const url = `/game/rating/global?sort=rating|desc&per_page=10000`;
      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setRatingData", res.data?.data);
          return res.data?.data;
        })
        .catch(err => console.log(err));
    },

    setPlan({ commit }, data) {
      const url = `/rgp_salary_plans`;

      return DefaultApiInstance.post(url, data)
        .then(res => {
          console.log(res);
        })
        .catch(e => console.log(e));
    },

    fetchPlanData({ commit, getters }) {
      const url = `/rgp_salary_plans`;

      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setPlans", res.data.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchPlanHistory({ commit }, { type, from, to }) {
      const url = `/rgp_salary_plans/${type}/history/month?date=${from}`;
      return DefaultApiInstance.get(url).then(res => {
        type === "kitchen"
          ? commit("setHistoryKitchenPlan", res.data.data)
          : commit("setHistoryClosetPlan", res.data.data);
        return res.data;
      });
    },

    fetchManagersByRgp({ getters, commit }, isRgp = false) {
      let baseUrl = "/grades/history";
      const date = getters.getSelectedMonth.from;

      let queryParams = isRgp
        ? `?grades[]=4&grades[]=5&with[]=user.supervisors&date=${date}`
        : getters.getRgpQueryParam + `date=${date}`;
      const url = baseUrl + queryParams;

      return DefaultApiInstance.get(url).then(res => {
        commit("setManagersWith3and4grade", res.data.data);
      });
    },

    fetchPrizeFond({ commit }, from) {
      const url = `/bonus/plans?date=${from}`;

      return DefaultApiInstance.get(url)
        .then(res => {
          console.log(res);
          commit("setPrizeFund", res.data);
        })
        .catch(e => {
          commit("setPrizeFund", {});
        });
    },

    fetchOverdueOrders({ commit }, { role, from }) {
      const url = `/${role}/overdubs?date=${from}`;

      return DefaultApiInstance.get(url)
        .then(res => {
          console.log(res);
          commit("setOverdueOrders", res.data);
        })
        .catch(e => {
          commit("setOverdueOrders", {});
        });
    },

    fetchNewbieOrders({ getters, commit }, { role, date }) {
      const url = `/${role}/orders/newbie/salary?date=${date}`;

      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setNewbieOrders", res.data);
          return res.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchTableData({ commit }, { id = null, date = null }) {
      return saleTable
        .fetchSaleTable(id, date)
        .then(res => {
          commit("setSaleTableData", res.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchManagersCertainRgp({ commit }) {
      return AuthApi.getAuthUserData()
        .then(res => {
          commit("setManagersCertainRgp", res.data.managers);
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
};
