import { AdminApi } from "@/api/adminApi";
import qs from "qs";
import { DefaultApiInstance } from "@/api";

export default {
  namespaced: true,
  state() {
    return {
      addUserFormVisible: false,
      user: null,
      selectedRoles: [],
      selectedStatus: [],
      users: [],
      selectedId1c: [],
      employmentAt: {
        from: null,
        to: null,
      },
    };
  },
  getters: {
    allUsers(state) {
      return state.users;
    },

    getUser(state) {
      return state.user;
    },
    getSelectedRoles(state) {
      return state.selectedRoles;
    },
    getSelected1cId(state) {
      return state.selectedId1c;
    },
    getSelectedStatus(state) {
      return state.selectedStatus;
    },
    getSelectedDate(state) {
      return state.employmentAt;
    },
    getCurrentFilter(state, getters) {
      let params = "";
      let filters = [];

      if (getters.getSelectedRoles.length) {
        filters.push({
          type: "in",
          key: "roles.id",
          value: getters.getSelectedRoles,
        });
      }

      if (getters.getSelected1cId.length) {
        filters.push({
          type: "in",
          key: "id1c",
          value: getters.getSelected1cId,
        });
      }
      if (getters.getSelectedStatus.length) {
        filters.push({
          type: "in",
          key: "status",
          value: getters.getSelectedStatus,
        });
      }

      if (getters.getSelectedDate.from && !getters.getSelectedDate.to) {
        filters.push({
          type: "between",
          key: "employment_at",
          value: {
            gte: [getters.getSelectedDate.from],
          },
        });
      }

      if (getters.getSelectedDate.to && !getters.getSelectedDate.from) {
        filters.push({
          type: "between",
          key: "employment_at",
          value: {
            lte: [getters.getSelectedDate.to],
          },
        });
      }

      if (getters.getSelectedDate.to && getters.getSelectedDate.from) {
        filters.push({
          type: "between",
          key: "employment_at",
          value: {
            lte: [getters.getSelectedDate.to],
            gte: [getters.getSelectedDate.from],
          },
        });
      }

      return (params = qs
        .stringify({ filters })
        .replaceAll("[", "[")
        .replaceAll("]", "]"));
    },
  },
  actions: {
    async fetchUsers({ commit }, role) {
      return await AdminApi.getUsers(role).then(res => {
        commit("setUsers", res.data);
      });
    },
    async fetchSearchedUsers({ commit }, { role, searchQuery }) {
      return await AdminApi.getSearchedUsers(role, searchQuery).then(res => {
        commit("setUsers", res.data);
      });
    },
    async fetchUser({ commit }, id) {
      return await AdminApi.getUser(id).then(res => {
        commit("setUser", res.data);
      });
    },
    async updateUser({ commit }, id) {
      return await AdminApi.updateUser(id).then(res => {
        // console.log(res);
        // commit("setUser", res.data);
      });
    },
    async removeUser({ commit }, id) {
      return await AdminApi.removeUser(id).then(res => {
        // console.log(res);
      });
    },

    showFilteredUsers({ state, getters, commit }, { userRole, page = 1 }) {
      let url = `${userRole}/users?with[]=roles&page=${page}`;
      if (getters.getCurrentFilter.length) {
        url += `&${getters.getCurrentFilter}`;
      }
      return DefaultApiInstance.get(url).then(res => {
        if (page === 1) {
          commit("setUsers", res.data);
        } else {
          commit("setMoreUsers", res.data.data);
        }
      });
    },
  },
  mutations: {
    setUserFormVisible(state) {
      state.addUserFormVisible = true;
    },
    setUserFormHide(state) {
      state.addUserFormVisible = false;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setUser(state, user) {
      state.user = user;
    },
    setMoreUsers(state, users) {
      state.users.data = [...state.users.data, ...users];
    },
    deleteUser(state) {
      state.user = null;
    },
    setSelectedRoles(state, value) {
      state.selectedRoles = value;
    },
    setSelectedId1c(state, value) {
      state.selectedId1c = value;
    },
    setSelectedStatus(state, value) {
      state.selectedStatus = value;
    },
    clearFilters(state) {
      state.selectedRoles = [];
      state.selectedId1c = [];
      state.employmentAt.to = null;
      state.employmentAt.from = null;
      state.selectedStatus = [];
    },
    setSelectedDate(state, { from, to }) {
      state.employmentAt.to = to;
      state.employmentAt.from = from;
    },
  },
};
