import { DefaultApiInstance } from "@/api";

export default {
  namespaced: true,
  state() {
    return {
      posts: [],
      lastActivePost: [],
    };
  },
  getters: {
    getPosts(state) {
      return state.posts;
    },

    getLastActiveDiscount(state) {
      return state.lastActivePost.length ? state.lastActivePost[0] : {};
    },
  },
  mutations: {
    setPosts(state, posts) {
      state.posts = posts;
    },

    setLastActivePost(state, post) {
      state.lastActivePost = post;
    },
  },
  actions: {
    fetchPosts({ commit }) {
      const url = `/posts?per_page=10000`;

      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setPosts", res.data.data);
        })
        .catch(e => console.log(e));
    },

    fetchLastPost({ commit }) {
      const url = `/posts?per_page=1`;

      return DefaultApiInstance.get(url).then(res => {
        commit("setLastActivePost", res.data.data);
      });
    },

    addPost(_, post) {
      const url = `/posts`;
      return DefaultApiInstance.post(url, post)
        .then(res => {
          console.log(res);
        })
        .catch(e => {
          console.log(e);
        });
    },

    removePost(_, id) {
      const url = `/posts/${id}`;
      return DefaultApiInstance.delete(url, id)
        .then(() => {
          alert("Новость удалена");
        })
        .catch(e => console.log(e));
    },

    changePost(_, payload) {
      console.log(payload);
      const url = `/posts/${payload.id}`;
      return DefaultApiInstance.post(url, payload.post)
        .then(() => {
          alert("Новость изменена");
        })
        .catch(e => console.log(e));
    },
  },
};
