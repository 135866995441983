import { DefaultApiInstance } from "@/api";

export const saleTable = {
  fetchSaleTable(id, date) {
    let url = `/user/payments`;

    if (id) {
      url += `?users[]=${id}`;
    }

    if (date) {
      if (id) {
        url += `&date=${date}`;
      } else {
        url += `?date=${date}`;
      }
    }
    return DefaultApiInstance.get(url);
  },
};
