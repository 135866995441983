<template>
  <div>
    <label class="my-select__label">{{ selectTitle }}</label>
    <v-select
      v-model="selected"
      class="my-select"
      :options="options"
      :placeholder="placeholder ? placeholder : selectTitle"
      :reduce="reduce"
      @input="setValue"
    ></v-select>
    <div class="my-select__error" v-show="isValidate">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
export default {
  name: "MySelect",
  data() {
    return {
      selected: null,
    };
  },
  props: {
    options: Array,
    reduce: Function,
    selectTitle: String,
    selectedValue: {},
    isValidate: {
      type: Boolean,
      default: false,
    },
    errorText: String,
    placeholder: String,
  },
  methods: {
    setValue(value) {
      this.$emit("setValue", value);
    },
    setSelectedValue() {
      this.selected = this.selectedValue;
    },
  },
  computed: {
    getSelectedValue() {
      return this.$attrs?.value;
    },
  },
  watch: {
    getSelectedValue(nv) {
      if (nv === null) {
        this.selected = null;
      }
    },
    selectedValue(nv) {
      this.selected = nv;
    },
  },
  mounted() {
    this.setSelectedValue();
  },
};
</script>

<style lang="scss">
@import "@/styles/UI/variables.scss";

.my-select .vs__search::placeholder,
.my-select .vs__dropdown-toggle,
.my-select .vs__dropdown-menu {
  background: $mainGray;
  border: none;
}

.my-select {
  --vs-dropdown-option--active-bg: #333;
  & .vs__selected-options {
    height: $inputHeight;
    max-height: calc($inputHeight * 2);
    overflow: scroll;
  }

  & .vs__selected-options::-webkit-scrollbar {
    display: none;
  }

  & .vs__dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  & .vs__dropdown-menu {
    margin-top: 5px;
  }

  & .vs__dropdown-toggle {
    border-radius: $mainBorderRadius;
    border: 2px solid $mainWhiteColor;
  }

  &.vs--open .vs__dropdown-toggle {
    border-radius: $mainBorderRadius;
    border: 2px solid $mainBlack;
  }

  & .vs__selected-options input::placeholder {
    font-size: $fontM;
  }

  & .vs__actions {
    width: 20%;
    justify-content: flex-end;
    cursor: pointer;

    button svg {
      transform: scale(0.8);
    }
  }

  & .vs__selected {
    padding-left: 12px;
  }

  &__label {
    font-size: $fontS;
    white-space: nowrap;
  }

  &__error {
    font-size: $fontS;
    color: $errorTextColor;
  }
}
</style>
