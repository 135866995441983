<template>
  <div class="order">
    <div class="main-page__header header">
      <div class="container">
        <div class="header__inner">
          <div class="header__info">
            <div class="header__logo">
              <HeaderIcon name="logo" />
            </div>
            <HeaderLinks />
          </div>
          <div class="header__actions">
            <UploadFileButton
              v-if="
                !toRoles.includes(userRole) &&
                !this.$route.name === 'addressSearch'
              "
            />
            <div
              v-if="this.getQueryParam === 'game' && windowWidth > 550"
              class="header__actions-username"
            >
              {{ getUserName }}
            </div>
          </div>
        </div>
        <StatisticHeader v-if="digestRoles.includes(userRole)" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import HeaderLinks from "@/components/header/HeaderLinks";
import UploadFileButton from "@/components/header/UploadFileButton.vue";
import HeaderIcon from "@/components/UI/HeaderIcon";
import ToOrderHeader from "@/components/to/to-secure-button/ToSecureButton.vue";
import StatisticHeader from "@/components/statistic/statistic-header/StatisticHeader.vue";
export default {
  name: "digest-layout",
  components: {
    StatisticHeader,
    HeaderLinks,
    HeaderIcon,
    UploadFileButton,
  },
  data() {
    return {
      toRoles: ["to", "supervisor_to"],
      digestRoles: ["admin", "sale_director", "director"],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    userRole() {
      return this.$store.state.authModule.userRole;
    },
    getQueryParam() {
      return this.$route.name;
    },
    getUserName() {
      return this.$store.state.authModule.user.name;
    },
  },
  methods: {
    navigateToDigest(e) {
      this.$router.push(e.target.dataset.digest);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.header__actions-username {
  font-weight: 600;
  font-size: 24px;
}
</style>
