<template>
  <div v-if="userRole !== 'price_editor'" class="input__wrapper">
    <input
      v-model="searchQuery"
      class="header__actions-input"
      type="number"
      placeholder="Поиск"
      @input="debounceFetchSearchedOrders()"
    />
    <div @click="clearSearchValue" v-if="isClose" class="input__close">X</div>
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import { mapState } from "vuex";
export default {
  name: "HeaderInput",
  data() {
    return {
      searchQuery: "",
      isClose: false,
      filters: [],
    };
  },

  computed: {
    getSavedSearchQuery() {
      return this.$store.getters["ordersModule/getSearchQuery"];
    },
    debounceFetchSearchedOrders() {
      return _debounce(this.fetchSearchedOrders, 500);
    },
    userRole() {
      return this.$store.state.authModule.userRole;
    },
  },
  methods: {
    fetchSearchedOrders() {
      this.$store.dispatch("ordersModule/getOrders");
    },

    clearSearchValue() {
      if (this.searchQuery.length > 0) this.searchQuery = "";
      this.$store.commit("ordersModule/setSearchQuery", "");
      this.fetchSearchedOrders();
    },
  },

  watch: {
    searchQuery(nv) {
      this.$store.commit("ordersModule/setSearchQuery", this.searchQuery);

      this.searchQuery.length > 0
        ? (this.isClose = true)
        : (this.isClose = false);
    },
    getSavedSearchQuery(nv) {
      if (nv.length === 0) {
        this.searchQuery = "";
      }
    },
  },
  mounted() {
    this.searchQuery = this.getSavedSearchQuery;
  },
};
</script>

<style lang="scss">
.input__wrapper {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}

.header__actions-input {
  position: relative;
}

.input {
  &__close {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    text-transform: lowercase;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
