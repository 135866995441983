import { DefaultApiInstance } from "@/api";
import { commit } from "lodash/seq";

export default {
  namespaced: true,
  state() {
    return {
      priceLists: [],
      bazisData: [],
      isBazis: false,
    };
  },
  getters: {
    getPriceLists(state) {
      return state.priceLists;
    },
    getLastPriceList(state, getters) {
      return getters.getPriceLists.length ? getters.getPriceLists[0] : [];
    },
    getBazisData(state) {
      return state.bazisData;
    },

    getLastBazisData(state, getters) {
      return getters.getBazisData.length ? getters.getBazisData[0] : [];
    },
    getIsBazis(state) {
      return state.isBazis;
    },
  },
  mutations: {
    setPriceLists(state, payload) {
      state.priceLists = payload;
    },
    setBazisData(state, payload) {
      state.bazisData = payload;
    },
    setIsBazis(state, payload) {
      state.isBazis = payload;
    },
  },
  actions: {
    fetchPriceLists({ commit }) {
      const url = "/price-lists";

      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setPriceLists", res.data.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchBazisData({ commit }) {
      const url = "bazis-data?per_page=1";

      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setBazisData", res.data.data);
        })
        .catch(e => console.log(e));
    },
  },
};
