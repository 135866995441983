<template>
  <div v-if="isVisible" class="loader">
    <template>
      <loader
        object="#333"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="50"
        disableScrolling="true"
        name="dots"
      ></loader>
    </template>
  </div>
</template>

<script>
export default {
  name: "MainLoader",
  props: {
    isVisible: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  z-index: 10000;
}
</style>
