<template>
  <div v-if="overlayVisible" @click="overlayHandler" class="overlay-main"></div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "overlayComponent",
  computed: {
    ...mapState({
      overlayVisible: state => state.overlayModule.isVisible,
    }),
  },
  methods: {
    ...mapMutations("topDigestModule", [
      "setIsSelectShow",
      "toggleGroupSelect",
    ]),
    overlayHandler() {
      this.$store.commit("overlayModule/hideOverlay");
      this.$store.commit("filtersModule/hideStatusFilter");
      this.$store.commit("filtersModule/hideStatusMppFilter");
      this.$store.commit("filtersModule/hideClientFilter");
      this.$store.commit("filtersModule/hideTimerFilter");
      this.$store.commit("filtersModule/hideCounterToFilter");
      this.$store.commit("filtersModule/hideCounterOneCFilter");
      this.$store.commit("filtersModule/hideManagerFilter");
      this.$store.commit("filtersModule/hideRegionFilter");
      this.$store.commit("filtersModule/clearSearchData");
      this.$store.commit("filtersModule/hideRgpFilter");
      this.$store.commit("filtersModule/hideFinishFilter");
      this.$store.commit("filtersModule/hideSignedAtFilter");
      this.$store.commit("filtersModule/hideToSpecialistFilter");
      this.$store.commit("paymentsModule/setIsDatePickerVisible", false);
      this.setIsSelectShow(false);
      this.toggleGroupSelect(false);
    },
  },
};
</script>

<style lang="scss">
.overlay-main {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: transparent;
}
</style>
