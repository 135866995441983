import { DefaultApiInstance } from "@/api";

export default {
  namespaced: true,
  state() {
    return {
      discountsList: [],
      lastActiveDiscount: {},
    };
  },
  getters: {
    getDiscountsList(state) {
      return state.discountsList;
    },

    getLastActiveDiscount(state) {
      return state.lastActiveDiscount.length ? state.lastActiveDiscount[0] : {};
    },
  },
  mutations: {
    setDiscountList(state, payload) {
      state.discountsList = payload;
    },

    setLastActiveDiscount(state, payload) {
      state.lastActiveDiscount = payload;
    },
  },
  actions: {
    fetchDiscountsList({ commit }) {
      const url = `/actions?per_page=1000`;
      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setDiscountList", res.data.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    fetchLastActiveDiscount({ commit }) {
      const url = `/actions?per_page=1&sort=id|desc&filters[0][type]=eq&filters[0][key]=status&filters[0][value][0]=enable`;

      return DefaultApiInstance.get(url).then(res => {
        commit("setLastActiveDiscount", res.data.data);
      });
    },

    addDiscount(_, discount) {
      const url = `/actions`;
      return DefaultApiInstance.post(url, discount)
        .then(res => {
          console.log(res);
        })
        .catch(e => {
          console.log(e);
        });
    },

    removeDiscount(_, id) {
      const url = `/actions/${id}`;
      return DefaultApiInstance.delete(url, id)
        .then(() => {
          alert("Акция удалена");
        })
        .catch(e => console.log(e));
    },

    changeDiscount(_, payload) {
      console.log(payload);
      const url = `/actions/${payload.id}`;
      return DefaultApiInstance.post(url, payload.discount)
        .then(() => {
          alert("Акция изменена");
        })
        .catch(e => console.log(e));
    },
  },
};
