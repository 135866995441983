<template>
  <div id="app">
    <OverlayComponent />
    <component :is="layout">
      <router-view />
    </component>
    <!-- <NotFoundPage /> -->
  </div>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout.vue";
import OrdersHeaderLayout from "@/layouts/OrdersHeaderLayout.vue";
import OrderHeaderLayout from "@/layouts/OrderHeaderLayout.vue";
import OverlayComponent from "./components/UI/OverlayComponent.vue";

import { mapState } from "vuex";
import digestLayout from "@/layouts/DigestLayout.vue";
import userListLayout from "@/layouts/UserListLayout.vue";
import AdminStatisticLayout from "@/layouts/AdminStatisticLayout.vue";
import PaymentsLayout from "@/layouts/PaymentsLayout.vue";
import prepaidSurchargeLayout from "@/layouts/PrepaidSurchargeLayout.vue";

export default {
  components: {
    LoginLayout,
    OrdersHeaderLayout,
    OrderHeaderLayout,
    OverlayComponent,
    digestLayout,
    userListLayout,
    AdminStatisticLayout,
    PaymentsLayout,
    prepaidSurchargeLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "login") + "-layout";
    },

    ...mapState({
      token: state => state.authModule.token,
    }),
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "styles/index.scss";
</style>
