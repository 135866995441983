import { OrderApi } from "@/api/orderApi";

export default {
  namespaced: true,
  state() {
    return {
      isHistory: true,
      isModal: false,
      isLogo: false,
      order: null,
      orderHistory: null,
      orderClient: null,
      total: null,
      perPage: null,
      isOrderLoading: false,
      isCommentsLoading: false,
      //
      isForToBlock: false,
      orderActiveTab: "history",
    };
  },
  getters: {
    pageCount(state) {
      return Math.ceil(state.total / state.perPage);
    },
    getIsOrderLoading(state) {
      return state.isOrderLoading;
    },
    getIsCommentsLoading(state) {
      return state.isCommentsLoading;
    },
    getIsToBlock(state) {
      return state.isForToBlock;
    },

    getOrderActiveTab(state) {
      return state.orderActiveTab;
    },
  },
  actions: {
    async fetchOrderHistory({ commit }, { role, id }) {
      return await OrderApi.getOrderHistory(role, id).then(res => {
        commit("setOrderHistory", res.data);
        commit("setTotalQty", res.data.meta.total);
        commit("setPerPage", res.data.meta.per_page);
      });
    },
    async fetchOrderHistoryByPage({ commit }, { role, id, page }) {
      return await OrderApi.getOrderHistoryByPage(role, id, page)
        .then(res => {
          commit("setOrderHistory", res.data);
        })
        .catch(err => alert(err));
    },

    async fetchAllOrderHistory({ commit }, { role, id, perPage }) {
      return await OrderApi.getAllOrderHistory(role, id, perPage).then(res => {
        commit("setOrderHistory", res.data);
        commit("setTotalQty", res.data.meta.total);
        commit("setPerPage", res.data.meta.per_page);
      });
    },
  },
  mutations: {
    setIsOrderLoading(state, isLoading) {
      state.isOrderLoading = isLoading;
    },
    setIsCommentsLoading(state, isLoading) {
      state.isCommentsLoading = isLoading;
    },
    setIsLogo(state, isLogo) {
      state.isLogo = !isLogo;
    },
    setIsHistory(state, isHistory) {
      state.isHistory = !isHistory;
    },
    setIsModal(state, isModal) {
      state.isModal = !isModal;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setOrderHistory(state, hisory) {
      state.orderHistory = hisory;
    },
    showHistory(state, boolean) {
      state.isHistory = boolean;
    },
    // pagination
    setTotalQty(state, qty) {
      state.total = qty;
    },
    setPerPage(state, qty) {
      state.perPage = qty;
    },

    deleteOrder(state) {
      state.order = null;
      state.orderHistory = null;
      state.orderComments = null;
      state.orderClient = null;
      localStorage.removeItem("order");
    },

    setIsForToBlock(state, boolean) {
      state.isForToBlock = boolean;
    },

    setOrderActiveTab(state, tab) {
      state.orderActiveTab = tab;
    },
  },
};
