import { DateHelpers } from "@/utils/helpers/DateHelpers";

export default {
  namespaced: true,
  state() {
    return {
      currentPlanData: {
        number_of_sales: null,
        sum_of_sales: null,
        avg_check: null,
        avg_prepaid: null,
      },
      planData: {
        number_of_sales: null,
        sum_of_sales: null,
        avg_check: null,
        avg_prepaid: null,
      },
    };
  },
  getters: {
    getCurrentPlanData(state) {
      return state.currentPlanData;
    },
    getPlanDataWithMonthNumber(state) {
      if (Object.values(state.planData).includes(null)) {
        return [];
      } else {
        return Object.entries(state.planData).map(([type, value]) => {
          return { type: type, value: DateHelpers.addMonthNumberByDate(value) };
        });
      }
    },
  },

  mutations: {
    setPlanValue(state, { planValue, type, current }) {
      if (current) {
        if (type === "number_of_sales") {
          state.currentPlanData.number_of_sales = planValue;
        }
        if (type === "sum_of_sales") {
          state.currentPlanData.sum_of_sales = planValue;
        }
        if (type === "avg_check") {
          state.currentPlanData.avg_check = planValue;
        }
        if (type === "avg_prepaid") {
          state.currentPlanData.avg_prepaid = planValue;
        }
      } else {
        if (type === "number_of_sales") {
          state.planData.number_of_sales = planValue;
        }
        if (type === "sum_of_sales") {
          state.planData.sum_of_sales = planValue;
        }
        if (type === "avg_check") {
          state.planData.avg_check = planValue;
        }
        if (type === "avg_prepaid") {
          state.planData.avg_prepaid = planValue;
        }
      }
    },

    clearCurrentPlanData(state) {
      const planData = state.currentPlanData;
      for (const key in planData) {
        planData[key] = null;
      }
    },
  },
};
