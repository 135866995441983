import { DefaultApiInstance } from "../index";

export const OrderApi = {
  getOrder(role, id) {
    // const url = `/${role}/orders/${id}?with[]=client.requisites&with[]=client.addresses&with[]=bonuses&with[]=bonuses.bonusProduct`;
    const url = `/${role}/orders/${id}?with[]=client.requisites&with[]=client.addresses&with[]=bonuses.bonusProduct&with[]=orderProperties&with[]=colors&with[]=payment1cs&with[]=payment1csHistory`;
    return DefaultApiInstance.get(url);
  },
  getOrderHistory(role, id) {
    const url = `/${role}/orders/${id}/history`;
    return DefaultApiInstance.get(url);
  },
  getOrderHistoryByPage(role, id, page) {
    const url = `/${role}/orders/${id}/history?page=${page}`;
    return DefaultApiInstance.get(url);
  },
  // временно для того,чтобы отрисовать всю историю заказов без ограничений
  getAllOrderHistory(role, id, perPage) {
    const url = `/${role}/orders/${id}/history?per_page=${perPage}`;
    return DefaultApiInstance.get(url);
  },
  getOrderComments(role, id) {
    const url = `/${role}/orders/${id}/comments`;
    return DefaultApiInstance.get(url);
  },

  getLogo(url) {
    return DefaultApiInstance.get(url);
  },
};
