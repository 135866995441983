import moment from "moment";

export const DateHelpers = {
  // help func to get week number

  getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const daysSinceYearStart = (date - firstDayOfYear) / (1000 * 60 * 60 * 24);

    return Math.ceil((daysSinceYearStart + firstDayOfYear.getDay() - 1) / 7);
  },

  // help func to get first and last day of week

  getFirstAndLastDaysOfWeek(weekNumber) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const daysToAdd = (weekNumber - 1) * 7 - firstDayOfYear.getDay();
    const startDate = new Date(currentYear, 0, 3 + daysToAdd)
      .toISOString()
      .slice(5, 10)
      .split("-")
      .reverse()
      .join(".");
    const endDate = new Date(currentYear, 0, 9 + daysToAdd)
      .toISOString()
      .slice(5, 10)
      .split("-")
      .reverse()
      .join(".");

    return { start: startDate, end: endDate };
  },

  getMonthIdByDate(date) {
    return moment(date).month();
  },

  addDatePeriod(array) {
    return array
      .map(el => {
        el["weekNumber"] = this.getWeekNumber(new Date(el.date));
        return el;
      })
      .map(el => {
        el["startDate"] = this.getFirstAndLastDaysOfWeek(el.weekNumber).start;
        el["endDate"] = this.getFirstAndLastDaysOfWeek(el.weekNumber).end;
        el["period"] = `${el["startDate"]}-${el["endDate"]}`;
        return el;
      });
  },

  addMonthNumberByDate(arrayOfObjects) {
    return arrayOfObjects.map(el => {
      let monthNumber = 0;
      let yearNumber = 0;
      let field = "";
      if (el.hasOwnProperty("date")) {
        monthNumber = new Date(el.date).getMonth();
        yearNumber = new Date(el.date).getFullYear();
        field = monthNumber.toString() + yearNumber.toString();
      } else {
        monthNumber = Number(el.date_from.slice(5, 7)) - 1;
        yearNumber = new Date(el.date_from).getFullYear();
        field = monthNumber.toString() + "" + yearNumber.toString();
      }
      el["monthNumber"] = monthNumber;
      return el;
    });
  },

  getMonthPeriod() {
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");

    return { startDate, endDate };
  },

  getCurrentYear() {
    return moment().year();
  },

  getCurrentMonth() {
    return moment().month();
  },
};
