import { DefaultApiInstance } from "@/api";

export const GameApi = {
  fetchAccountsData() {
    const url = "/account";

    return DefaultApiInstance.get(url);
  },

  fetchGlobalRating() {
    const url = "/game/rating/global?sort=rating|desc&per_page=10000";

    return DefaultApiInstance.get(url);
  },
};
