export default {
  namespaced: true,
  state() {
    return {
      selectedBonuses: [],
      selectedKitchenType: null,
      length1From: null,
      length1To: null,
      length2From: null,
      length2To: null,
      length3From: null,
      length3To: null,
      regions: [],
      bonuses: [],
    };
  },
  getters: {
    getSelectedKitchenType(state) {
      return state.selectedKitchenType;
    },
    getLength1From(state) {
      return state.length1From;
    },
    getLength1To(state) {
      return state.length1To;
    },
    getLength2From(state) {
      return state.length2From;
    },
    getLength2To(state) {
      return state.length2To;
    },

    getLength3From(state) {
      return state.length3From;
    },
    getLength3To(state) {
      return state.length3To;
    },

    getRegions(state) {
      return state.regions;
    },
    getBonuses(state) {
      return state.bonuses;
    },
  },
  mutations: {
    setKitchenType(state, payload) {
      state.selectedKitchenType = payload;
    },
    setLength1From(state, payload) {
      state.length1From = payload;
    },
    setLength1To(state, payload) {
      state.length1To = payload;
    },
    setLength2From(state, payload) {
      state.length2From = payload;
    },
    setLength2To(state, payload) {
      state.length2To = payload;
    },
    setLength3From(state, payload) {
      state.length3From = payload;
    },
    setLength3To(state, payload) {
      state.length3To = payload;
    },

    setRegions(state, regions) {
      state.regions = regions;
    },

    setBonuses(state, bonuses) {
      state.bonuses = bonuses;
    },
  },
};
