import { TopDigestApi } from "@/api/topDigestApi";
import _sumBy from "lodash/sumBy";
import _sum from "lodash/sum";

export default {
  namespaced: true,
  state() {
    return {
      region: {
        selectedRegion: {
          code: "all",
          created_at: "",
          id: null,
          name: "все регионы",
        },
        isSelectedRegion: false,
      },
      activeRegions: null,
      from: null,
      to: null,
      grouping: {
        value: "days",
        isSelectShow: false,
      },
      numberOfSales: {
        plan: [],
        fact: [],
        factLastPeriod: [],
      },
      sumOfSales: {
        plan: [],
        fact: [],
        factLastPeriod: [],
      },
      avgCheck: {
        fact: null,
        factLastPeriod: null,
        plan: null,
        lastPeriodFrom: null,
        lastPeriodTo: null,
        factComponentsCost: null,
        lastPeriodComponentsCost: null,
        factCost: null,
        lastPeriodCost: null,
        isComponentsChartVisible: false,
      },
      avgPrepaidPercent: {
        fact: null,
        fact1c: null,
        factLastPeriod: null,
        factLastPeriod1c: null,
        plan: null,
        lastPeriodFrom: null,
        lastPeriodTo: null,
      },
      totalSales: {
        total: null,
        citiesFrom: null,
        citiesTo: null,
      },

      totalSalesCities: {
        label: "Продажа",
      },

      //  скрыть и показать пирог

      isCitiesChartVisible: true,
      isTotalSaleChartVisible: true,

      //   показать чарт по городам

      isAllCitiesChartVisible: false,
    };
  },
  getters: {
    // grouping

    getGroupValue(state) {
      return state.grouping.value;
    },

    getIsGroupSelectShow(state) {
      return state.grouping.isSelectShow;
    },

    getIsSelectShow(state) {
      return state.region.isSelectedRegion;
    },

    getSelectedRegion(state) {
      return state.region.selectedRegion;
    },

    getActiveRegions(state) {
      return state.activeRegions;
    },

    getFrom(state) {
      return state.from;
    },

    getTo(state) {
      return state.to;
    },
    // numbers of sales

    getNumberOfSalesPlan(state) {
      return state.numberOfSales.plan;
    },

    getNumberOfSalesFact(state) {
      return state.numberOfSales.fact;
    },
    getNumberOfSalesFactLastPeriod(state) {
      return state.numberOfSales.factLastPeriod;
    },
    // sums of sales

    getSumOfSalesPlan(state) {
      return state.sumOfSales.plan;
    },

    getSumOfSalesFact(state) {
      return state.sumOfSales.fact;
    },
    getSumOfSalesFactLastPeriod(state) {
      return state.sumOfSales.factLastPeriod;
    },

    // avgCheck

    getAvgCheckPlan(state) {
      return !state.avgCheck.plan ? 0 : Number(state.avgCheck.plan).toFixed(2);
    },

    getAvgCheckFact(state) {
      return !state.avgCheck.fact?.data.avg_amount_mpp
        ? 0
        : Number(state.avgCheck.fact?.data.avg_amount_mpp).toFixed(2);
    },

    getAvgCheckFactPriceKg(state) {
      return !state.avgCheck.fact?.data.avg_price_kg
        ? 0
        : Number(state.avgCheck.fact?.data.avg_price_kg).toFixed(2);
    },

    getAvgCheckFactLastPeriod(state) {
      return !state.avgCheck.factLastPeriod?.data.avg_amount_mpp
        ? 0
        : Number(state.avgCheck.factLastPeriod?.data.avg_amount_mpp).toFixed(2);
    },

    getAvgCheckFactLastPeriodPriceKg(state) {
      return !state.avgCheck.factLastPeriod?.data.avg_price_kg
        ? 0
        : Number(state.avgCheck.factLastPeriod?.data.avg_price_kg).toFixed(2);
    },

    getAvgCheckLastPeriodFrom(state) {
      return state.avgCheck?.lastPeriodFrom;
    },

    getAvgCheckLastPeriodTo(state) {
      return state.avgCheck.lastPeriodTo;
    },

    getAvgCheckComponentsData(state) {
      return {
        fact: state.avgCheck.factComponentsCost,
        lastPeriod: state.avgCheck.lastPeriodComponentsCost,
      };
    },

    getAvgCheckCost(state) {
      return {
        fact: state.avgCheck.factCost,
        lastPeriod: state.avgCheck.lastPeriodCost,
      };
    },

    getAvgCheckComponentsCost(state) {
      return {
        fact: state.avgCheck.factComponentsCost,
        lastPeriod: state.avgCheck.lastPeriodComponentsCost,
      };
    },

    getAvgCheckIsComponentsChartVisible(state) {
      return state.avgCheck.isComponentsChartVisible;
    },

    // avgPrepaidPercent

    getAvgPrepaidPercentPlan(state) {
      return !state.avgPrepaidPercent.plan
        ? 0
        : Number(state.avgPrepaidPercent.plan).toFixed(2);
    },

    getAvgPrepaidPercentFact(state) {
      return !state.avgPrepaidPercent.fact
        ? 0
        : Number(state.avgPrepaidPercent.fact).toFixed(2);
    },

    getAvgPrepaidPercentFact1c(state) {
      return !state.avgPrepaidPercent.fact1c
        ? 0
        : Number(state.avgPrepaidPercent.fact1c).toFixed(2);
    },

    getAvgPrepaidPercentFactLastPeriod(state) {
      return !state.avgPrepaidPercent.factLastPeriod
        ? 0
        : Number(state.avgPrepaidPercent.factLastPeriod).toFixed(2);
    },

    getAvgPrepaidPercentFactLastPeriod1c(state) {
      return !state.avgPrepaidPercent.factLastPeriod1c
        ? 0
        : Number(state.avgPrepaidPercent.factLastPeriod1c).toFixed(2);
    },

    getAvgPrepaidPercentLastPeriodFrom(state) {
      return state.avgPrepaidPercent?.lastPeriodFrom;
    },

    getAvgPrepaidPercentLastPeriodTo(state) {
      return state.avgPrepaidPercent?.lastPeriodTo;
    },

    // total sales

    getTotalSalesData(state) {
      return state.totalSales.total?.length ? state.totalSales.total : [];
    },

    getTotalSalesGroupByCities(_, getters) {
      return getters.getTotalSalesData.map(sales => {
        const values = [];
        Object.entries(sales).forEach(([key, value]) => {
          if (
            key === "total_hold_production" ||
            key === "total_re_measurement" ||
            key === "total_sold"
          )
            values.push(sales[key]);
        });
        return { ...sales, total: _sum(values) };
      });
    },

    getSalesFields(_, getters) {
      const keys = [];
      getters.getTotalSalesGroupByCities.length
        ? Object.entries(getters.getTotalSalesGroupByCities[0]).forEach(
            ([key, value]) => {
              if (typeof value === "number") keys.push(key);
            }
          )
        : null;

      return keys;
    },

    getTotalSales(_, getters) {
      const sumOfSales = {};
      getters.getSalesFields.forEach(el => {
        sumOfSales[el] = _sumBy(getters.getTotalSalesGroupByCities, el);
      });
      return sumOfSales;
    },

    getTotalSalesCitiesLabel(state, getters) {
      if (state.totalSalesCities.label === "Отложенное")
        return "total_hold_production";
      if (state.totalSalesCities.label === "Продажа") return "total_sold";
      if (state.totalSalesCities.label === "Перезамер")
        return "total_re_measurement";
    },

    // isCitiesChartVisible
    getIsCitiesChartVisible(state) {
      return state.isCitiesChartVisible;
    },
    getIsTotalSaleChartVisible(state) {
      return state.isTotalSaleChartVisible;
    },

    getAllCitiesChartVisible(state) {
      return state.isAllCitiesChartVisible;
    },
  },

  actions: {
    async fetchPlanData(
      { state, commit },
      { role, type, period, create = false }
    ) {
      const regionId = state.region.selectedRegion.id || null;

      if (create) {
        return TopDigestApi.getPlan(role, type, period, regionId);
      } else {
        return TopDigestApi.getPlan(role, type, period, regionId).then(res => {
          if (type === "number_of_sales") {
            commit("setNumberOfSalesData", res.data);
          } else if (type === "sum_of_sales") {
            commit("setSumOfSalesData", res.data);
          } else if (type === "avg_check") {
            commit("setAvgCheckData", res.data);
          } else if (type === "avg_prepaid") {
            commit("setAvgPrepaidPercentData", res.data);
          }
        });
      }
    },

    async fetchTotalSalesData({ commit }, { role, period }) {
      return TopDigestApi.fetchTotalSalesData(role, period).then(res => {
        commit("setTotalSales", res.data);
      });
    },

    async fetchActiveRegions({ commit }, { role, from, to }) {
      return TopDigestApi.fetchActiveRegions({ role, from, to }).then(res => {
        commit("setActiveRegions", res.data.data);
      });
    },
  },

  mutations: {
    setGrouping(state, groupValue) {
      state.grouping.value = groupValue;
    },
    toggleGroupSelect(state, boolean) {
      state.grouping.isSelectShow = boolean;
    },
    setActiveRegions(state, regions) {
      state.activeRegions = regions;
    },

    setTo(state, to) {
      state.to = to;
    },

    setFrom(state, from) {
      state.from = from;
    },
    // Number of sales

    setNumberOfSalesData(state, data) {
      state.numberOfSales.plan = data.plan;
      state.numberOfSales.fact = data.fact;
      state.numberOfSales.factLastPeriod = data.last;
    },

    // sum of sales

    setSumOfSalesData(state, data) {
      state.sumOfSales.plan = data.plan;
      state.sumOfSales.fact = data.fact;
      state.sumOfSales.factLastPeriod = data.last;
    },

    // avgCheck

    setAvgCheckData(state, data) {
      state.avgCheck.fact = data.fact;
      state.avgCheck.plan = data.plan;
      state.avgCheck.factLastPeriod = data.last;
      state.avgCheck.lastPeriodFrom = data.last.period.from;
      state.avgCheck.lastPeriodTo = data.last.period.to;
      state.avgCheck.factComponentsCost = data.fact.components;
      state.avgCheck.lastPeriodComponentsCost = data.last.components;
      state.avgCheck.factCost = data.fact.cost;
      state.avgCheck.lastPeriodCost = data.last.cost;
    },

    setAvgCheckGroupBy(state, group) {
      state.avgCheck.groupBy = group;
    },

    toggleComponentsChartVisible(state, boolean) {
      state.avgCheck.isComponentsChartVisible = boolean;
    },

    // AvgPrepaidPercent

    setAvgPrepaidPercentData(state, data) {
      state.avgPrepaidPercent.fact = data.fact.data.avg_prepaid;
      state.avgPrepaidPercent.fact1c = data.fact.data.avg_amount_1c;
      state.avgPrepaidPercent.plan = data.plan;
      state.avgPrepaidPercent.factLastPeriod = data.last.data.avg_prepaid;
      state.avgPrepaidPercent.factLastPeriod1c = data.last.data.avg_amount_1c;
      state.avgPrepaidPercent.lastPeriodFrom = data.last.period.from;
      state.avgPrepaidPercent.lastPeriodTo = data.last.period.to;
    },

    // total sales

    setTotalSales(state, sales) {
      state.totalSales.total = sales;
    },

    // total sales cities

    setTotalSalesCitiesLabel(state, label) {
      state.totalSalesCities.label = label;
    },

    //   region select

    setSelectedRegion(state, region) {
      state.region.selectedRegion = region;
    },

    setIsSelectShow(state, boolean) {
      state.region.isSelectedRegion = boolean;
    },

    setIsCitiesChartVisible(state, boolean) {
      state.isCitiesChartVisible = boolean;
    },

    setIsTotalSaleChartVisible(state, boolean) {
      state.isTotalSaleChartVisible = boolean;
    },

    setAllCitiesChartVisible(state, boolean) {
      state.isAllCitiesChartVisible = boolean;
    },
  },
};
