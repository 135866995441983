import { DefaultApiInstance } from "@/api";

export const documentsApi = {
  fetchDocuments: () => {
    const url = "/documents";
    return DefaultApiInstance.get(url);
  },

  fetchCompanies: () => {
    const url = "/companies";
    return DefaultApiInstance.get(url);
  },

  fetchKeys: (listType = "documents", id) => {
    const url = `/${listType}/${id}/keywords`;

    return DefaultApiInstance.get(url);
  },

  updateKey: data => {
    const { listType, documentId, keyId, key, description } = data;
    const url = `/${listType}/${documentId}/keywords/${keyId}`;

    return DefaultApiInstance.put(url, { key, description });
  },

  updateDocumentOrCompany: data => {
    const { listType, id, name } = data;
    const url = `/${listType}/${id}`;

    return DefaultApiInstance.put(url, { name });
  },

  removeKey: data => {
    const { listType, documentId, keyId } = data;
    const url = `/${listType}/${documentId}/keywords/${keyId}`;

    return DefaultApiInstance.delete(url);
  },

  addKeys: data => {
    const { listType, id, keys } = data;
    const url = `/${listType}/${id}/keywords`;
    return DefaultApiInstance.post(url, { data: keys });
  },

  addCompany: name => {
    const url = `/companies`;

    return DefaultApiInstance.post(url, { name });
  },

  removeDocumentOrCompany: data => {
    const { listType, id } = data;
    const url = `/${listType}/${id}`;

    return DefaultApiInstance.delete(url);
  },
};
