<template>
  <button v-if="getVideoUrl">
    <a :href="getVideoUrl" target="_blank" class="game-button">
      COMS: Kingdom of Sales
    </a>
  </button>
</template>

<script>
export default {
  data() {
    return {
      url: window.location.host,
    };
  },
  computed: {
    getVideoUrl() {
      return "https://api." + this.url + "/storage/sales-kingdom.mp4";
    },
  },
};
</script>

<style lang="scss" scoped>
.game-button {
  text-align: center;
  font-weight: bold;
  font-size: 15px;

  cursor: pointer;

  background: linear-gradient(
    to right,
    #fff 20%,
    #d7cc3e 40%,
    rgba(147, 178, 54, 0.99) 60%,
    #fff 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 2s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}
</style>
