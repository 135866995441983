<template>
  <div v-if="credentials.token" class="main-page">
    <div class="main-page__header header">
      <div class="container">
        <div class="header__inner">
          <div class="header__info">
            <div class="header__logo">
              <HeaderIcon name="logo" />
            </div>
            <HeaderLinks />
            <!--            <GameButton />-->
          </div>
          <div class="header__actions">
            <ResetFilterButton v-if="this.getQueryParam !== 'userlist'" />
            <ExcelButton v-if="this.getQueryParam !== 'userlist'" />
            <HeaderInput v-if="userRole !== 'marketer'" />
            <UploadFileButton />
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import ExcelButton from "@/components/header/ExcelButton.vue";
import HeaderInput from "@/components/header/HeaderInput.vue";
import HeaderLinks from "@/components/header/HeaderLinks";
import ResetFilterButton from "@/components/header/ResetFilterButton.vue";
import UploadFileButton from "@/components/header/UploadFileButton.vue";
import HeaderIcon from "@/components/UI/HeaderIcon.vue";
import { mapState } from "vuex";
import GameButton from "@/components/header/GameButton.vue";
export default {
  name: "orders-layout",
  components: {
    // GameButton,
    HeaderLinks,
    HeaderIcon,
    UploadFileButton,
    HeaderInput,
    ResetFilterButton,
    ExcelButton,
  },

  computed: {
    ...mapState("authModule", ["credentials"]),
    getQueryParam() {
      return this.$route.name;
    },
    userRole() {
      return localStorage.getItem("userRole");
    },
  },
  methods: {},
};
</script>
