import { DefaultApiInstance } from "@/api";
import { GameApi } from "@/api/gameApi";

export default {
  namespaced: true,
  state() {
    return {
      grades: null,
      data: null,
      isLoading: false,
      currentOutfits: [],
      rating: null,
      allUsers: null,
      currentOutfitsWithGradeID: null,
      meta: {},
      tasksForManager: {},
      nextGrade: {},
      globalRating: JSON.parse(localStorage.getItem("globalRating")) || [],
    };
  },
  getters: {
    getData(state) {
      return state.data;
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    getGradesWithTasks(state) {
      return state.grades;
    },
    getGrades(state, getters) {
      const grades = getters.getGradesWithTasks;
      const gradesNames = grades?.map(g => g.grade);
      return gradesNames?.reduce((acc, el) => {
        if (el === "militiaman")
          acc[el] = { name: "Ополченец", level: "0", title: el, gradeId: 1 };
        if (el === "rookie")
          acc[el] = { name: "Новобранец", level: "I", title: el, gradeId: 2 };
        if (el === "foot_warrior")
          acc[el] = { name: "Пеший воин", level: "II", gradeId: 3 };
        if (el === "rider")
          acc[el] = {
            name: "Воин на коне",
            level: "III",
            title: el,
            gradeId: 4,
          };
        if (el === "archangel")
          acc[el] = { name: "Архангел", level: "IV", title: el, gradeId: 5 };
        return acc;
      }, {});
    },
    getCurrentGrade(state, getters) {
      const grade = getters.getData?.grade?.grade;
      return getters.getGrades ? getters.getGrades[grade] : null;
    },
    getOutfits(state, getters) {
      return getters.getData?.outfits;
    },
    getCurrentOutfits(state) {
      return state.currentOutfits;
    },
    getRating(state) {
      return state.rating;
    },
    getRatingList(state) {
      return state.rating?.data;
    },

    getUsersWithMonthBonuses(state, getters) {
      const medalsTitle = [
        "Самый высокий средний чек",
        "Самая высокая стоимость заказа",
        "Самая высокая стоимость КГ",
        "Самая высокая наценка на КГ",
      ];
      const users = getters.getRatingList;

      return users?.reduce((acc, el) => {
        const userMedals = [...el.onlyMedalBonuses];

        const monthMedals = userMedals.filter(el =>
          medalsTitle.includes(el.description)
        );

        if (monthMedals.length) {
          acc.push({ user: el.user, monthMedals, userValues: el });
        }
        return acc;
      }, []);
    },

    getLeaders(state, getters) {
      const medalsTitle = "грейд";
      const users = getters.getRatingList;
      return users?.reduce((acc, el) => {
        const userMedals = [...el.onlyMedalBonuses];

        const monthMedals = userMedals.filter(m => m.grade_id && m.place);

        if (monthMedals.length) {
          acc.push({ user: el.user, medal: monthMedals[0] });
        }
        return acc;
      }, []);
    },

    getLeadersGroupByGrade(state, getters) {
      const leaders = getters.getLeaders;
      return leaders?.reduce((acc, el) => {
        const grade = el.medal.grade_id;
        const position = el.medal.place;
        !acc.hasOwnProperty(grade)
          ? (acc[grade] = [{ ...el.user, position: position, medal: el.medal }])
          : acc[grade].push({
              ...el.user,
              position: position,
              medal: el.medal,
            });
        return acc;
      }, {});
    },

    getTasksForManager(state) {
      return state.tasksForManager;
    },
    getNextGrade(state) {
      return state.nextGrade;
    },

    getGlobalRating(state) {
      return state.globalRating;
    },

    getLeadersFromGlobalGrade(state, getters) {
      return [
        {
          position: 1,
          user: getters.getGlobalRating[0],
          id: getters.getGlobalRating[0]?.user_id,
        },
        {
          position: 2,
          user: getters.getGlobalRating[1],
          id: getters.getGlobalRating[1]?.user_id,
        },
        {
          position: 3,
          user: getters.getGlobalRating[2],
          id: getters.getGlobalRating[2]?.user_id,
        },
      ];
    },

    getAllUsers(state) {
      return state.allUsers;
    },
    getMeta(state, getters) {
      return getters.getRating?.meta;
    },
  },
  mutations: {
    setGrades(state, payload) {
      state.grades = payload;
    },
    setData(state, data) {
      state.data = data;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setCurrentOutfits(state, outfits) {
      state.currentOutfits = outfits;
    },
    setRating(state, data) {
      state.rating = data;
    },
    setTasksForManager(state, tasks) {
      state.tasksForManager = tasks;
    },
    setNextGrade(state, grade) {
      state.nextGrade = grade;
    },
    setGlobalRating(state, rating) {
      state.globalRating = rating;
      localStorage.setItem("globalRating", JSON.stringify(rating));
    },
    clearGlobalRating(state) {
      state.globalRating = [];
      localStorage.removeItem("globalRating");
    },
  },
  actions: {
    fetchData({ state, commit }) {
      commit("setIsLoading", true);
      return GameApi.fetchAccountsData()
        .then(res => {
          commit("setData", res.data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            commit("setIsLoading", false);
          }, 300);
        });
    },

    fetchDataForAdmin({ state, commit }, id) {
      const url = `/account?account_id=${id}`;
      commit("setIsLoading", true);
      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setData", res.data);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            commit("setIsLoading", false);
          }, 300);
        });
    },

    fetchGrades({ state, commit }) {
      const url = "/grades";
      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setGrades", res.data.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchGradesForAdmin({ state, commit }, id) {
      const url = `/grades?account_id=${id}`;
      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setGrades", res.data.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    fetchGlobalRating({ state, commit }, perPage = null) {
      commit("setIsLoading", true);

      return GameApi.fetchGlobalRating()
        .then(res => {
          commit("setRating", res.data);
          commit("setGlobalRating", res.data.data);
        })
        .finally(() => {
          setTimeout(() => {
            commit("setIsLoading", false);
          }, 300);
        });
    },

    fetchGradeRating({ state, commit }, { perPage = null, id }) {
      commit("setIsLoading", true);
      let url = `game/rating/grade/${id}?sort=rating|desc&per_page=10000`;
      // perPage ? (url += `&per_page=${perPage}`) : url + "";

      return DefaultApiInstance.get(url)
        .then(res => {
          commit("setRating", res.data);
        })
        .finally(() => {
          setTimeout(() => {
            commit("setIsLoading", false);
          }, 300);
        });
    },
  },
};
