import { OrderApi } from "@/api/orderApi";

export default {
  namespaced: true,
  state() {
    return {
      comments: null,
    };
  },
  getters: {},
  actions: {
    async fetchOrderComments({ commit }, { role, id }) {
      return await OrderApi.getOrderComments(role, id).then(res => {
        console.log(res);
        commit("setComments", res.data);
      });
    },
  },
  mutations: {
    setComments(state, comment) {
      state.comments = comment;
    },
    clearComments(state) {
      state.comments = null;
    },
  },
};
