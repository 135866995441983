import { DefaultApiInstance } from "@/api";

export const DictionaryApi = {
  fetchManagersType() {
    const url = "/dictionary/manager/types";
    return DefaultApiInstance.get(url);
  },

  fetchRoles() {
    const url = "/roles";
    return DefaultApiInstance.get(url);
  },

  fetchDefaultRegionOptions(userRole) {
    const url = `/${userRole}/regions`;
    return DefaultApiInstance.get(url);
  },
};
