import axios from "axios";
import { apiUrl } from "../../config.json";

const loginConfig = {
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
};

export const LoginApiInstance = axios.create(loginConfig);

const defaultConfig = {
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
};

// добавление токена к хедерам

const token = localStorage.getItem("token");

if (token) defaultConfig.headers["authorization"] = `Bearer ${token}`;

export const DefaultApiInstance = axios.create(defaultConfig);
