import { DefaultApiInstance } from "../index";

export const TopDigestApi = {
  getPlan(role, type, { from, to }, id) {
    const regionId = id ? `&region_id=${id}` : "";
    const url =
      type === "number_of_sales"
        ? `${role}/plans/${type}/data?from=${from}&to=${to}${regionId}`
        : type === "sum_of_sales"
        ? `${role}/plans/${type}/data?from=${from}&to=${to}${regionId}`
        : type === "avg_check"
        ? `${role}/plans/avg_check/data?from=${from}&to=${to}${regionId}`
        : type === "avg_prepaid"
        ? `${role}/plans/avg_prepaid/data?from=${from}&to=${to}${regionId}`
        : `${role}/plans/sales/data?from=${from}&to=${to}`;
    return DefaultApiInstance.get(url);
  },

  fetchTotalSalesData(role, { from, to }) {
    const url = `${role}/plans/sales/data?from=${from}&to=${to}`;
    return DefaultApiInstance.get(url);
  },

  fetchAnalytic(role) {
    const url = `${role}/plans/analytic?from=2023-08-01&to=2023-08-07`;
    return DefaultApiInstance.get(url);
  },

  fetchActiveRegions({ role, from, to }) {
    const period = from && to ? `?from=${from}&to=${to}` : "";
    const url = `${role}/plans/regions${period}`;
    return DefaultApiInstance.get(url);
  },

  fetchPlanHistory({ role, type, regionId }) {
    const id = regionId ? `?region_id=${regionId}` : "";
    const url = `${role}/plans/${type}/history/month${id}`;
    return DefaultApiInstance.get(url);
  },

  createPlan(role, data, regionId) {
    const id = regionId ? `?region_id=${regionId}` : "";
    const url = `${role}/plans${id}`;
    return DefaultApiInstance.post(url, data);
  },
};
