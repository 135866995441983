var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__info-buttons"},[(!_vm.hrRoles.includes(_vm.userRole))?_c('router-link',{staticClass:"header__info-btn orders-btn",attrs:{"exact-active-class":"active","to":"/orders"}},[_c('HeaderIcon',{attrs:{"name":"orders"}}),_vm._v(" Заказы ")],1):_vm._e(),(_vm.hrRoles.includes(_vm.userRole))?_c('router-link',{staticClass:"header__info-btn user-btn",attrs:{"active-class":"active","to":"/newbie-awards"}},[_c('HeaderIcon',{attrs:{"name":"usersList"}}),_vm._v(" Пользователи ")],1):_vm._e(),(_vm.userRole === 'admin')?_c('router-link',{staticClass:"header__info-btn user-btn",attrs:{"active-class":"active","to":"/users-list"}},[_c('HeaderIcon',{attrs:{"name":"usersList"}}),_vm._v(" Пользователи ")],1):_vm._e(),(
      !_vm.toRoles.includes(_vm.userRole) &&
      _vm.userRole !== 'bonus_product_specialist' &&
      _vm.userRole !== 'marketer' &&
      _vm.userRole !== 'price_editor' &&
      _vm.userRole !== 'hr'
    )?_c('router-link',{staticClass:"header__info-btn statistic-btn",attrs:{"active-class":"active","to":"/statistic"}},[_c('HeaderIcon',{attrs:{"name":"statistic"}}),_vm._v(" Дайджест ")],1):_vm._e(),(_vm.addressSearchRoles.includes(_vm.userRole))?_c('router-link',{staticClass:"header__info-btn statistic-btn",attrs:{"active-class":"active","to":"/address-search"}},[_c('HeaderIcon',{attrs:{"name":"top-digest"}}),_vm._v(" База проектов ")],1):_vm._e(),(_vm.paymentsRoles.includes(_vm.userRole))?_c('router-link',{staticClass:"header__info-btn statistic-btn",attrs:{"active-class":"active","to":_vm.awardsCounterRole.includes(_vm.userRole) ? '/payments/awards' : '/payments'}},[_c('HeaderIcon',{attrs:{"name":"payments"}}),_vm._v(" Зарплата "),(_vm.awardsCounterRole.includes(_vm.userRole))?_c('RawAwardsCount'):_vm._e()],1):_vm._e(),(_vm.statisticRoles.includes(_vm.userRole))?_c('router-link',{staticClass:"header__info-btn statistic-btn",attrs:{"active-class":"active","to":"/admin-statistic"}},[_c('HeaderIcon',{attrs:{"name":"managers-list"}}),_vm._v(" Статистика ")],1):_vm._e(),(_vm.userRole === 'admin')?_c('router-link',{staticClass:"header__info-btn user-btn",attrs:{"active-class":"active","to":"/game/rating"}},[_c('HeaderIcon',{attrs:{"name":"game"}}),_vm._v(" Рейтинг ")],1):(_vm.userRole === 'manager')?_c('router-link',{staticClass:"header__info-btn user-btn",attrs:{"active-class":"active","to":"/game"}},[_c('HeaderIcon',{attrs:{"name":"game"}}),_vm._v(" Игра ")],1):_vm._e(),_c('router-link',{staticClass:"header__info-btn user-btn",attrs:{"active-class":"active","to":"/user"}},[_c('HeaderIcon',{attrs:{"name":"user"}}),_vm._v(" Личный кабинет ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }