import { DefaultApiInstance } from "@/api";
import qs from "qs";

export default {
  namespaced: true,
  state() {
    return {
      period: {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 2)
          .toISOString()
          .slice(0, 10),
        to: new Date(new Date().setMonth(new Date().getMonth() + 1, 0))
          .toISOString()
          .slice(0, 10),
      },
      colors: null,
    };
  },
  getters: {
    getPeriod(state) {
      return state.period;
    },

    getColors(state) {
      return state.colors?.data;
    },

    getSortedOrders(state, getters) {
      return getters.getColors?.sort((a, b) => b.cnt - a.cnt);
    },
  },
  mutations: {
    setColors(state, colors) {
      state.colors = colors;
    },
    setPeriod(state, { from, to }) {
      state.period = { from, to };
    },
  },
  actions: {
    fetchColors({ getters, commit }) {
      let url = "/orders/colors?";
      const filters = [];
      let params = "";

      filters.push({
        type: "between",
        key: "signed_at",
        value: {
          gte: [getters.getPeriod.from],
          lte: [getters.getPeriod.to],
        },
      });

      params = qs
        .stringify({ filters })
        .replaceAll("%5B", "[")
        .replaceAll("%5D", "]");

      url += params;

      return DefaultApiInstance.get(url).then(res => {
        commit("setColors", res);
      });
    },
  },
};
