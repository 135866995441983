<template>
  <div class="payments-header">
    <MainLoader :is-visible="isPageLoading" />
    <div class="payments-header__tabs">
      <div
        v-for="tab in getTabsList"
        :key="tab.id"
        @click="navigateToPayments($event)"
        :class="{ active: getActiveTab === tab.data }"
        :data-payments="tab.data"
        class="payments-header__tab"
      >
        {{ tab.title }}

        <RawAwardsCount v-if="tab.title === 'Премии'" />
      </div>
    </div>
  </div>
</template>

<script>
import MainLoader from "@/components/UI/loader/Loader.vue";
import RawAwardsCount from "@/components/payments/awards/raw-awards-count/RawAwardsCount.vue";

export default {
  name: "PaymentsHeader",
  components: { RawAwardsCount, MainLoader },
  data() {
    return {
      activeTab: localStorage.getItem("payments-page") || "/payments",
      isPageLoading: false,
      tabsList: [
        {
          id: 0,
          data: "/payments/awards",
          title: "Премии",
          role: ["admin", "sale_director", "director"],
        },
        {
          id: 1,
          data: "/payments",
          title: "ЗП МПП",
          role: ["admin", "director", "sale_director", "rgp"],
        },
        {
          id: 2,
          data: "/payments/rgp",
          title: "ЗП РГП",
          role: ["admin", "director", "sale_director", "rgp"],
        },
      ],
    };
  },
  computed: {
    getActiveTab() {
      return this.activeTab;
    },
    userRole() {
      return this.$store.getters["authModule/getUserRole"];
    },
    getTabsList() {
      const tabs = this.tabsList;
      return tabs.filter(t => t.role.includes(this.userRole));
    },
  },
  methods: {
    navigateToPayments(e) {
      this.isPageLoading = true;
      this.activeTab = e.target.dataset.payments;
      localStorage.setItem("payments-page", e.target.dataset.payments);
      this.$router
        .push(e.target.dataset.payments)
        .finally(() => (this.isPageLoading = false));
    },
  },
  mounted() {
    this.activeTab = this.$route.path;
  },
  beforeDestroy() {
    localStorage.removeItem("digest");
  },
};
</script>

<style lang="scss" scoped>
.payments-header {
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__tab {
    padding: 15px;
    cursor: pointer;
    &.active {
      border-bottom: 2px solid #333333;
    }
  }
}
</style>
