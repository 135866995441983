export default {
  namespaced: true,
  state() {
    return {
      isVisible: false,
    };
  },
  mutations: {
    showOverlay(state) {
      state.isVisible = true;
    },
    hideOverlay(state) {
      state.isVisible = false;
    },
  },
  getters: {},
};
