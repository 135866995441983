<template>
  <div class="admin-statistic-tabs">
    <div class="admin-statistic-tabs__tabs">
      <div
        @click="navigateToPage($event)"
        data-digest="/admin-statistic"
        class="admin-statistic-tabs__tab"
        :class="{ active: getActiveTab === '/admin-statistic' }"
      >
        Скачивание
      </div>
      <div
        @click="navigateToPage($event)"
        data-digest="/admin-statistic/colors"
        class="admin-statistic-tabs__tab"
        :class="{ active: getActiveTab === '/admin-statistic/colors' }"
      >
        Цвета
      </div>
      <div
        @click="navigateToPage($event)"
        data-digest="/admin-statistic/not-sold-filter"
        class="admin-statistic-tabs__tab"
        :class="{ active: getActiveTab === '/admin-statistic/not-sold-filter' }"
      >
        Неактивные пользователи
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminStatisticTabs",
  data() {
    return {
      activeTab:
        localStorage.getItem("admin-statistic-tabs") || "/admin-statistic",
      searchQuery: "",
    };
  },
  computed: {
    getActiveTab() {
      return this.activeTab;
    },

    userRole() {
      return this.$store.state.authModule.userRole;
    },
  },
  methods: {
    navigateToPage(e) {
      this.activeTab = e.target.dataset.digest;
      localStorage.setItem("admin-statistic-tabs", e.target.dataset.digest);
      this.$router.push(e.target.dataset.digest);
    },
  },
  watch: {
    getActiveTab() {
      this.searchQuery = "";
    },
  },
  beforeDestroy() {
    localStorage.removeItem("admin-statistic-tabs");
  },
};
</script>

<style lang="scss" scoped>
.admin-statistic-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 0 auto;
  }

  &__tab {
    padding: 15px;
    cursor: pointer;
    &.active {
      border-bottom: 2px solid #333333;
    }
  }
}

.header__actions-input {
  width: 300px;
}
</style>
