import { TopDigestApi } from "@/api/topDigestApi";

export default {
  namespaced: true,
  state() {
    return {
      planHistory: null,
    };
  },
  getters: {},
  actions: {
    fetchPlanHistory({ commit }, { role, type }) {
      return TopDigestApi.fetchPlanHistory({ role, type }).then(res =>
        commit("setPlanHistory", res.data)
      );
    },
  },
  mutations: {
    setPlanHistory(state, history) {
      state.planHistory = history;
    },
  },
};
