<template>
  <div class="raw-awards-count">
    <span class="raw-awards-count__text">
      {{ awardsCount }}
    </span>
  </div>
</template>

<script>
export default {
  name: "RawAwardsCount",
  data() {
    return {
      awardsCount: localStorage.getItem("raw-awards-count") || 0,
    };
  },
  computed: {
    getAwardsCount() {
      const awardsList =
        this.$store.getters["awardsModule/getAwardsListForRawAward"];
      return [...awardsList]
        .sort((a, b) => b.value - a.value)
        .filter(a => a.description !== "Премия новичка")
        .map(a => {
          const isRejected = a.rejected_at;
          const isAccepted = a.accepted_at;
          const status =
            !isRejected && !isAccepted
              ? "На рассмотрении"
              : isRejected && !isAccepted
              ? "Отклонено"
              : "Согласовано";
          return { ...a, status };
        })
        .filter(a => a.status === "На рассмотрении").length;
    },
  },
  watch: {
    getAwardsCount(nv) {
      localStorage.setItem("raw-awards-count", nv);
      this.awardsCount = nv;
    },
  },
  mounted() {
    this.awardsCount = this.getAwardsCount;
  },
};
</script>

<style lang="scss" scoped>
.raw-awards-count {
  width: 20px;
  height: 20px;
  font-size: 12px;
  background: #eeeeee;
  padding: 5px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
